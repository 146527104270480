import { getBankIcon } from "../../modules/Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard, faUniversity } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import PropTypes from "prop-types";

export const WithdrawalAccount = ({ fromBankAccount }) => {
  return (
    <>
      <div className="bank-logo">
        {fromBankAccount ? (
          <img src={getBankIcon(fromBankAccount.institution)} alt="Bank Logo" />
        ) : (
          <FontAwesomeIcon icon={faUniversity} />
        )}
      </div>
      <h5>Withdrawal account</h5>
      <p>
        {fromBankAccount ? (
          `${fromBankAccount.institution} - ${fromBankAccount.title} - ${fromBankAccount.caRoutingInfo.accountNumber}`
        ) : (
          <span className="text-muted font-italic">Pending authorization</span>
        )}
      </p>
    </>
  );
};

WithdrawalAccount.propTypes = {
  fromBankAccount: PropTypes.object,
};

export const CreditCardWithdrawalAccount = ({ customerCreditCard }) => {
  return (
    <>
      <div className="bank-logo">
        <FontAwesomeIcon icon={faCreditCard} />
      </div>
      <h5>Payer credit card</h5>
      <p>
        {customerCreditCard?.brand && customerCreditCard?.last4 ? (
          `${customerCreditCard.brand} - ending in ${customerCreditCard.last4}`
        ) : (
          <span className="text-muted font-italic">
            Could not collect credit card details
          </span>
        )}
      </p>
    </>
  );
};

CreditCardWithdrawalAccount.propTypes = {
  customerCreditCard: PropTypes.object,
};
