/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Col, Collapse, Row } from "reactstrap";
import {
  baseUrl,
  CREDIT,
  DEBIT,
  formattedDollarValue,
  getCurrentState,
} from "../../../modules/Helpers";
import { statusPill } from "../../../components/badge/StatusBadge";
import { CustomLoader } from "../../../components/loaders/CustomLoader";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import useGQL from "../../../api_client/UseGQL";
import warningAlert from "../../../components/sweet_alert/WarningAlert";
import dangerAlert from "../../../components/sweet_alert/DangerAlert";
import { transactionDetail } from "../../../api_client/queries/transactions";
import {
  deleteDebit,
  resendTransactionRequestEmail,
} from "../../../api_client/mutations/transaction";
import TransactionPaidFromCard from "../../../components/cards/TransactionPaidFromCard";
import TransactionPaidToCard from "../../../components/cards/TransactionPaidToCard";
import TransactionInfoCard from "../../../components/cards/TransactionInfoCard";
import TransactionTimeline from "./TransactionTimeline";
import TransactionProgressBar from "../../../components/progress-bars/TransactionProgressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import TransactionActionsMobileDropdown from "../../../components/dropdowns/TransactionActionsMobileDropdown";
import TransactionsActionsDesktop from "./TransactionActionsDesktop";
import { statusPillEmail } from "../../../components/badge/StatusBadgeEmail";
import InvoiceInfoCard from "components/cards/InvoiceInfoCard";

function TransactionDetails(props) {
  const [transaction, setTransaction] = useState({});
  const [loading, setLoading] = useState(true);
  const { addToast } = useToasts();
  const history = useHistory();
  let gqlHooks = useGQL();
  const [alertState, setAlertState] = useState(null);
  const [showHistory, setShowHistory] = useState(false);
  const toggleHistory = () => setShowHistory(!showHistory);
  const customer = props.customer;
  const showEmailStatus =
    transaction.transactionRequestedContactEmail?.status &&
    transaction.states[0].state === 7;

  useEffect(() => {
    if (props.id) {
      setLoading(true);
      props.setBackdropClick(true);
      const identifier = props.id;
      let input = {
        transactionId: identifier,
      };
      let output = {
        amount: true,
        description: true,
        direction: true,
        padType: true,
        statement: true,
        method: true,
        recurringPlan: {
          identifier: true,
          frequency: true,
        },
        state: {
          createdAt: true,
          updatedAt: true,
          identifier: true,
          state: true,
          reason: true,
        },
        states: {
          createdAt: true,
          updatedAt: true,
          identifier: true,
          state: true,
          reason: true,
        },
        identifier: true,
        issuedBy: {
          email: true,
        },
        company: {
          name: true,
          identifier: true,
          settings: {
            notesAlias: true,
          },
        },
        toBankAccount: {
          "... on BankAccountObject": {
            caRoutingInfo: {
              institutionNumber: true,
              accountNumber: true,
            },
            institution: true,
            title: true,
          },
          "... on PublicBankAccountObject": {
            caRoutingInfo: {
              accountNumber: true,
            },
            institution: true,
            title: true,
          },
        },
        fromBankAccount: {
          "... on PublicBankAccountObject": {
            caRoutingInfo: {
              accountNumber: true,
            },
            institution: true,
            title: true,
          },
          "... on BankAccountObject": {
            caRoutingInfo: {
              accountNumber: true,
            },
            institution: true,
            title: true,
          },
        },
        createdAt: true,
        updatedAt: true,
        estDepositDate: true,
        contact: {
          identifier: true,
          email: true,
          name: true,
        },
        invoice: {
          identifier: true,
          amount: true,
          invoiceNumber: true,
        },
        customerCreditCard: {
          brand: true,
          last4: true,
        },
        railzConnection: {
          serviceName: true,
          businessName: true,
        },
        transactionRequestedContactEmail: {
          status: true,
        },
        availableTransitions: true,
      };

      if (customer) {
        const { toBankAccount, ...rest } = output;
        output = rest;
      }
      transactionDetail(input, output, gqlHooks).then((response) => {
        if (response && response.data) {
          if (response.data.direction === DEBIT) {
            response.data.link = `${baseUrl}/payment/pay/${response.data.identifier}`;
            setTransaction(response.data);
          } else {
            response.data.link = `${baseUrl}/payment/status/${response.data.identifier}`;
            setTransaction(response.data);
          }
          setLoading(false);
          props.setBackdropClick(false);
        } else {
          history.push({ search: "" });
          addToast("Could not load transaction", {
            appearance: "warning",
            autoDismiss: true,
          });
        }
      });
    }
  }, [props.id]);

  if (loading) {
    return (
      <div className="content">
        <CustomLoader size={30} general={true} />
      </div>
    );
  }

  const confirmDelete = () => {
    warningAlert(
      () => setAlertState(null),
      setAlertState,
      <Button className="btn-warning" onClick={deletePaymentRequest}>
        {transaction.states[0].state === 8 ? "Delete" : "Cancel"} payment
        request
      </Button>,
      `Are you sure you want to ${
        transaction.states[0].state === 8 ? "delete" : "cancel"
      } this payment request?`
    );
  };

  const deletePaymentRequest = async () => {
    setLoading(true);
    props.setBackdropClick(true);
    let input = { transactionId: transaction.identifier };
    let output = {};
    const response = await deleteDebit(input, output, gqlHooks);
    if (response) {
      setAlertState(null);
      history.push({ search: "update=true" });
      addToast(
        transaction.states[0].state === 8
          ? "Payment request deleted"
          : "Payment request cancelled",
        {
          appearance: "success",
          autoDismiss: true,
        }
      );
      setLoading(false);
      props.setBackdropClick(false);
    } else {
      dangerAlert(
        () => setAlertState(null),
        setAlertState,
        "Failed to cancel payment request"
      );
    }
  };

  const resendPaymentRequestEmail = async () => {
    setLoading(true);
    props.setBackdropClick(true);
    let input = { identifier: transaction.identifier };
    let output = { ok: true };
    const response = await resendTransactionRequestEmail(
      input,
      output,
      gqlHooks
    );
    if (response) {
      setAlertState(null);
      addToast("Payment request email sent", {
        appearance: "success",
        autoDismiss: true,
      });
      setLoading(false);
      props.setBackdropClick(false);
    } else {
      dangerAlert(
        () => setAlertState(null),
        setAlertState,
        "Failed to send email, please contact support if this issue persists."
      );
    }
  };

  const confirmResendPaymentRequestEmail = () => {
    warningAlert(
      () => setAlertState(null),
      setAlertState,
      <Button className="btn-primary" onClick={resendPaymentRequestEmail}>
        Resend email
      </Button>,
      "Are you sure you want to resend this payment request email?"
    );
  };

  const copyToClipboard = () => {
    addToast(
      "Copied to clipboard, include in an invoice or email you send to your contact.",
      { appearance: "success", autoDismiss: true }
    );
  };

  const copyIdToClipboard = () => {
    addToast("Payment id copied to clipboard", {
      appearance: "success",
      autoDismiss: true,
    });
  };

  function ticket() {
    history.push({
      search: `ticket=true&transactionId=${transaction.identifier}`,
    });
  }

  return (
    <>
      {alertState}
      <div className="content">
        <Row>
          <Col className="col-sm col-sm-6 col-md-3">
            <p className="text-muted mb-0 mt-2">
              {transaction.direction === DEBIT
                ? "Receivable"
                : transaction.direction === CREDIT
                ? "Payable"
                : "Transfer"}
            </p>
          </Col>
          <Col>
            <Button
              className="btn-link details-close-btn"
              disabled={loading}
              onClick={() => history.goBack()}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
            <div className="details-desktop-actions">
              <TransactionsActionsDesktop
                ticket={ticket}
                transaction={transaction}
                copyPaymentLink={copyToClipboard}
                resendPaymentEmail={confirmResendPaymentRequestEmail}
                cancelPayment={confirmDelete}
              />
            </div>
            <div className="details-mobile-actions">
              <TransactionActionsMobileDropdown
                ticket={ticket}
                transaction={transaction}
                copyPaymentLink={copyToClipboard}
                resendPaymentEmail={confirmResendPaymentRequestEmail}
                cancelPayment={confirmDelete}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex align-items-center">
              <h3 className="font-weight-600 mb-0">
                {transaction.direction === CREDIT
                  ? `(${formattedDollarValue(transaction.amount)})`
                  : formattedDollarValue(transaction.amount)}
              </h3>
              <div className="ml-2">
                {showEmailStatus ? (
                  <>
                    {statusPillEmail(
                      transaction.transactionRequestedContactEmail?.status,
                      transaction
                    )}
                  </>
                ) : (
                  <>
                    {statusPill(
                      getCurrentState(transaction.states).state,
                      transaction
                    )}
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <TransactionProgressBar
          transaction={transaction}
          toggleHistory={toggleHistory}
          showHistoryState={showHistory}
          setAlertState={setAlertState}
          loading={loading}
          setLoading={setLoading}
          setBackdropClick={props.setBackdropClick}
        />
        <Collapse isOpen={showHistory}>
          <TransactionTimeline
            transaction={transaction}
            states={transaction.states}
          />
        </Collapse>
        <TransactionInfoCard
          notesAlias={transaction.company.settings.notesAlias}
          useGivenNotes={true}
          transaction={transaction}
          copyIdToClipboard={copyIdToClipboard}
        />
        <TransactionPaidFromCard transaction={transaction} />
        {customer ? "" : <TransactionPaidToCard transaction={transaction} />}
        <InvoiceInfoCard transaction={transaction} />
      </div>
    </>
  );
}

export default TransactionDetails;
TransactionDetails.propTypes = {
  id: PropTypes.string.isRequired,
  toggle: PropTypes.func,
  setBackdropClick: PropTypes.func,
};
