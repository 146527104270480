import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Fade,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import PropTypes from "prop-types";
import useForm from "../../../hooks/UseForms";
import { BusinessInformationValidation } from "../../../modules/form_validation/BusinessInformationValidation";
import useGQL from "../../../api_client/UseGQL";
import { PulseLoader } from "react-spinners";
import { trackEvent } from "../../../modules/analytics";
import { submitCompanyKyc } from "../../../api_client/mutations/kyc";
import Select from "react-select";
import {
  appName,
  CustomTooltip,
  domains,
  industries,
  purposes,
  nextOnboardingPage,
} from "../../../modules/Helpers";
import { useHistory } from "react-router";
import { withApiValue } from "../../../modules/WithApiValue";
import OnboardingDocuments from "../../../components/forms/OnboardingDocuments";
import useImageUpload from "../../../hooks/UseImageUpload";
import defaultImage from "../../../assets/img/DocumentPlaceholder.png";
import success from "../../../assets/img/onboarding/AddLegalInfoCompleteUpdated.svg";
import { useToasts } from "react-toast-notifications";

const config = {
  company: {
    companyType: true,
    kyc: {
      industry: true,
      companyAttestation: true,
      purpose: true,
      submitted: true,
      customerInvoice: true,
      bankStatements1: true,
      bankStatements2: true,
      bankStatements3: true,
      websiteUrl: true,
      articleOfIncorporation: true,
      amendmentsDocument: true,
      dateOfIncorporation: true,
      customerSupportContactPhone: true,
      refreshFields: true,
      resubmitted: true,
      updateRequired: true,
    },
  },
};

function OnboardingIndividualLegal(props) {
  const { values, handleChange, errors, handleSubmit, setValues } = useForm(
    submit,
    BusinessInformationValidation
  );
  const [loading, setLoading] = useState(false);
  const [companyAttestation, setCompanyAttestation] = useState(false);
  const refs = {
    industry: useRef(),
    purpose: useRef(),
    websiteUrl: useRef(),
    dateOfIncorporation: useRef(),
    customerSupportContactPhone: useRef(),
  };

  let gqlHooks = useGQL();
  const [industry, setIndustry] = useState();
  const [purpose, setPurpose] = useState([]);
  const [otherSelected, setOtherSelected] = useState(false);
  const [other, setOther] = useState("");
  const history = useHistory();
  const [tooltipPurposeIsOpen, setTooltipPurposeIsOpen] = useState(false);
  const [tooltipWebsiteIsOpen, setTooltipWebsiteIsOpen] = useState(false);
  const [documentsUploaded, setDocumentsUploaded] = useState(false);
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);
  const fileInputRef5 = useRef(null);
  const fileInputRef6 = useRef(null);
  const [updateIndividualLegalInfo, setUpdateIndividualLegalInfo] =
    useState(true);
  const customerInvoice = useImageUpload(
    fileInputRef1,
    defaultImage,
    "INVOICE"
  );
  const bankStatements1 = useImageUpload(
    fileInputRef2,
    defaultImage,
    "STATEMENTS",
    "bankStatements1"
  );
  const bankStatements2 = useImageUpload(
    fileInputRef3,
    defaultImage,
    "STATEMENTS",
    "bankStatements2"
  );
  const bankStatements3 = useImageUpload(
    fileInputRef4,
    defaultImage,
    "STATEMENTS",
    "bankStatements3"
  );
  const articleOfIncorporation = useImageUpload(
    fileInputRef5,
    defaultImage,
    "ARTICLE"
  );
  const amendmentsDocuments = useImageUpload(
    fileInputRef6,
    defaultImage,
    "AMENDMENTS"
  );
  const { addToast } = useToasts();
  const [submitClicked, setSubmitClicked] = useState(false);

  useEffect(() => {
    if (
      customerInvoice.imageState.file &&
      bankStatements1.imageState.file &&
      bankStatements2.imageState.file &&
      bankStatements3.imageState.file
    ) {
      setDocumentsUploaded(true);
    }
  }, [
    bankStatements1.imageState,
    bankStatements2.imageState,
    bankStatements3.imageState,
    customerInvoice.imageState,
  ]);

  useEffect(() => {
    if (Boolean(localStorage.getItem("legalInformation")) === true) {
      setSubmitClicked(true);
    }

    let updateValues = props.company.kyc.refreshFields
      ? JSON.parse(props.company.kyc.refreshFields)["Legal Information"]
      : [];
    if (
      updateValues &&
      localStorage.getItem("legalInformationUpdated") === "false"
    ) {
      for (const updateValue of updateValues) {
        switch (updateValue) {
          case "Business website":
            props.company.kyc.websiteUrl = null;
            break;
          case "Industry":
            props.company.kyc.industry = null;
            break;
          case "Date of Incorporation":
            props.company.kyc.dateOfIncorporation = null;
            break;
          case "Customer Support Contact Phone":
            props.company.kyc.customerSupportContactPhone = null;
            break;
          case "Purpose of transactions":
            props.company.kyc.purpose = "";
            break;
          case "Recent customer invoice":
            props.company.kyc.customerInvoice = "";
            break;
          case "3 most recent bank statements":
            props.company.kyc.bankStatements1 = "";
            props.company.kyc.bankStatements2 = "";
            props.company.kyc.bankStatements3 = "";
            break;
          case "Articles of Incorporation":
            props.company.kyc.articleOfIncorporation = "";
            break;
          case "Amendments Document":
            props.company.kyc.amendmentsDocument = "";
            break;
          default:
            break;
        }
      }
    }

    if (props.company?.kyc) {
      if (props.company?.kyc.customerInvoice !== "") {
        customerInvoice.setImageState({
          file: { name: props.company.kyc.customerInvoice },
        });
      }
      if (props.company?.kyc.bankStatements1 !== "") {
        bankStatements1.setImageState({
          file: { name: props.company.kyc.bankStatements1 },
        });
      }
      if (props.company?.kyc.bankStatements2 !== "") {
        bankStatements2.setImageState({
          file: { name: props.company.kyc.bankStatements2 },
        });
      }
      if (props.company?.kyc.bankStatements3 !== "") {
        bankStatements3.setImageState({
          file: { name: props.company.kyc.bankStatements3 },
        });
      }
      if (props.company?.kyc.articleOfIncorporation !== "") {
        articleOfIncorporation.setImageState({
          file: { name: props.company.kyc.articleOfIncorporation },
        });
      }
      if (props.company?.kyc.amendmentsDocument !== "") {
        amendmentsDocuments.setImageState({
          file: { name: props.company.kyc.amendmentsDocument },
        });
      }
    }
    if (props.company && props.company.kyc.companyAttestation) {
      setPurpose(
        purposes.filter(({ value }) =>
          props.company.kyc?.purpose?.split(", ").some((n) => value.includes(n))
        )
      );
      setDocumentsUploaded(props.company.kyc.submitted);
      setValues({
        websiteUrl: props.company.kyc.websiteUrl,
        dateOfIncorporation: props.company.kyc.dateOfIncorporation,
        customerSupportContactPhone:
          props.company.kyc.customerSupportContactPhone,
        ...values,
      });
      setIndustry({
        value: props.company.kyc?.industry,
        label: props.company.kyc?.industry,
      });
    }
    trackEvent("onboardingLegalInfoIndividual", {}, gqlHooks);
  }, [props.company]);

  function onSelectPurpose(value) {
    let otherSelect = false;
    if (value === null) {
      value = [];
    }
    value.forEach((p) => {
      if (p.value === "Other") {
        otherSelect = true;
      }
    });
    if (!otherSelect) {
      setOther("");
    }
    setOtherSelected(otherSelect);
    setPurpose(value);
  }

  async function submit() {
    localStorage.setItem("legalInformationUpdated", "true");
    if (
      !articleOfIncorporation.imageState.file ||
      articleOfIncorporation.imageState.file.name === ""
    ) {
      addToast("Please upload company documents before continuing", {
        appearance: "warning",
        autoDismiss: true,
      });
      return;
    }
    setLoading(true);

    let input = {
      purpose: purpose
        .map((p) => {
          if (p.value === "Other") {
            return other;
          } else {
            return p.value;
          }
        })
        .join(", "),
      industry: industry.value,
      companyAttestation: companyAttestation,
      websiteUrl: values.websiteUrl,
      dateOfIncorporation: new Date(values.dateOfIncorporation).toISOString(),
      customerSupportContactPhone: values.customerSupportContactPhone,
    };
    if (bankStatements1.imageState.file?.size < 1000000) {
      await bankStatements1.handleImageSubmit();
    }
    if (bankStatements2.imageState.file?.size < 1000000) {
      await bankStatements2.handleImageSubmit();
    }
    if (bankStatements3.imageState.file?.size < 1000000) {
      await bankStatements3.handleImageSubmit();
    }
    if (customerInvoice.imageState.file?.size < 1000000) {
      await customerInvoice.handleImageSubmit();
    }
    if (articleOfIncorporation.imageState.file?.size < 1000000) {
      await articleOfIncorporation.handleImageSubmit();
    }
    if (amendmentsDocuments.imageState.file?.size < 1000000) {
      await amendmentsDocuments.handleImageSubmit();
    }
    const response = await submitCompanyKyc(input, {}, gqlHooks);
    if (response && response.kyc) {
      trackEvent("legalInformationSubmitted", {}, gqlHooks);
      setUpdateIndividualLegalInfo(false);
      response.company = { kyc: response.kyc };
      delete response.kyc;
      props.update(response);
    }
    setLoading(false);
    setSubmitClicked(true);
    localStorage.setItem("legalInformation", true);
  }

  function toggleUpdateLegalInfo() {
    setUpdateIndividualLegalInfo(true);
    trackEvent("editLegalInformation", {}, gqlHooks);
  }

  return (
    <Fragment>
      <Row className="mr-0 ml-0">
        <Col className="m-auto" style={{ maxWidth: 600 }}>
          <Fade>
            {updateIndividualLegalInfo ? (
              <Fragment>
                <h2 className="text-center mb-2 font-weight-600">
                  Add legal information
                </h2>
                <p className="text-muted text-center mb-3">
                  Learn why we need this information{" "}
                  <a
                    href={`https://${domains.HELP}/why-does-jetpay-need-this-information/`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                  .
                </p>
                <Form className="v-form" onSubmit={handleSubmit}>
                  <Label className="mt-2">Website URL</Label>
                  <CustomTooltip
                    title={`You must have a business website in order to use ${appName}`}
                    placement="top"
                    arrow
                    open={tooltipWebsiteIsOpen}
                    onOpen={() => setTooltipWebsiteIsOpen(true)}
                    onClose={() => setTooltipWebsiteIsOpen(false)}
                    leaveTouchDelay={10000}
                  >
                    <Button
                      onClick={() =>
                        setTooltipWebsiteIsOpen(!tooltipPurposeIsOpen)
                      }
                      variant="contained"
                      className="btn-link tooltip-button"
                    >
                      <i className="fas fa-info-circle" />
                    </Button>
                  </CustomTooltip>
                  <FormGroup>
                    <Input
                      name="websiteUrl"
                      type="text"
                      innerRef={refs.websiteUrl}
                      value={values.websiteUrl || "https://"}
                      invalid={!!errors.websiteUrl}
                      onChange={handleChange}
                      required
                    />
                    <FormFeedback>{errors.websiteUrl}</FormFeedback>
                  </FormGroup>
                  <Label>Industry</Label>
                  <FormGroup>
                    <Select
                      onChange={setIndustry}
                      className="react-select default"
                      classNamePrefix="react-select"
                      name="industry"
                      value={industry}
                      options={industries}
                      placeholder="Select..."
                      ref={refs.industry}
                    />
                    <Input
                      className="p-0 m-0"
                      name="industry"
                      style={{ opacity: 0, height: 0 }}
                      value={industry}
                      readOnly
                      required
                    />
                    <FormFeedback>{errors.industry}</FormFeedback>
                  </FormGroup>
                  <Row>
                    <Col md>
                      <FormGroup>
                        <Label>Date of Business Registration</Label>
                        <Input
                          name="dateOfIncorporation"
                          type="date"
                          innerRef={refs.dateOfIncorporation}
                          value={values.dateOfIncorporation || ""}
                          onChange={handleChange}
                          invalid={!!errors.dateOfIncorporation}
                          min="0001-01-01"
                          max="9999-12-31"
                          required
                        />
                        <FormFeedback>
                          {errors.dateOfIncorporation}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Customer Support Contact Phone</Label>
                        <Input
                          name="customerSupportContactPhone"
                          type="text"
                          innerRef={refs.customerSupportContactPhone}
                          value={values.customerSupportContactPhone || ""}
                          onChange={handleChange}
                          invalid={!!errors.customerSupportContactPhone}
                          required
                        />
                        <FormFeedback>
                          {errors.customerSupportContactPhone}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Label>Purpose of transactions</Label>
                  <CustomTooltip
                    title="State the nature of the transactions you will be conducting."
                    placement="top"
                    arrow
                    open={tooltipPurposeIsOpen}
                    onOpen={() => setTooltipPurposeIsOpen(true)}
                    onClose={() => setTooltipPurposeIsOpen(false)}
                    leaveTouchDelay={10000}
                  >
                    <Button
                      onClick={() =>
                        setTooltipPurposeIsOpen(!tooltipPurposeIsOpen)
                      }
                      variant="contained"
                      className="btn-link tooltip-button"
                    >
                      <i className="fas fa-info-circle" />
                    </Button>
                  </CustomTooltip>
                  <FormGroup>
                    <Select
                      onChange={onSelectPurpose}
                      className="react-select default"
                      classNamePrefix="react-select"
                      name="purpose"
                      value={purpose}
                      options={purposes}
                      placeholder="Select all that apply..."
                      ref={refs.purpose}
                      isMulti={true}
                      closeMenuOnSelect={false}
                    />
                    <Input
                      className="p-0 m-0"
                      name="purpose"
                      style={{ opacity: 0, height: 0 }}
                      value={purpose}
                      readOnly
                      required
                    />
                    <FormFeedback>{errors.purpose}</FormFeedback>
                  </FormGroup>
                  {otherSelected && (
                    <>
                      <Label>Other purpose</Label>
                      <FormGroup>
                        <Input
                          name="other"
                          value={other}
                          onChange={(event) => setOther(event.target.value)}
                          placeholder="Please describe other purpose"
                          required
                        />
                      </FormGroup>
                    </>
                  )}
                  <div>
                    <OnboardingDocuments
                      customerInvoice={customerInvoice}
                      bankStatements1={bankStatements1}
                      bankStatements2={bankStatements2}
                      bankStatements3={bankStatements3}
                      articleOfIncorporation={articleOfIncorporation}
                      amendmentsDocuments={amendmentsDocuments}
                      isOrganization={false}
                      fileInputRef1={fileInputRef1}
                      fileInputRef2={fileInputRef2}
                      fileInputRef3={fileInputRef3}
                      fileInputRef4={fileInputRef4}
                      fileInputRef5={fileInputRef5}
                      fileInputRef6={fileInputRef6}
                    />
                  </div>
                  <div>
                    <FormGroup inline check>
                      <Label className="form-check-label">
                        <Input
                          id="cb-1"
                          className="form-check-input"
                          type="checkbox"
                          checked={companyAttestation}
                          onChange={() =>
                            setCompanyAttestation(!companyAttestation)
                          }
                        />
                        <span className="form-check-sign">
                          <span className="check" />
                        </span>
                        I am not acting under the instruction of a third party
                        and certify all information provided is correct
                      </Label>
                    </FormGroup>
                  </div>
                  <Button
                    type="submit"
                    block
                    className="mt-4"
                    color="primary"
                    disabled={
                      !documentsUploaded ||
                      !companyAttestation ||
                      loading ||
                      purpose.length === 0 ||
                      !industry
                    }
                  >
                    {loading ? (
                      <PulseLoader color="white" size={10} />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Form>
              </Fragment>
            ) : (
              <Fragment>
                <div className="text-center">
                  <img
                    src={success}
                    alt="Legal Information Submitted"
                    width="100"
                    height="100"
                    className="m-auto d-block"
                  />
                  <h2 className="text-center mt-3 mb-4 font-weight-600">
                    Legal information submitted!
                    <i
                      style={{ fontSize: "14px" }}
                      className="ml-2 cursor-pointer text-primary"
                      onClick={toggleUpdateLegalInfo}
                    >
                      Update
                    </i>
                  </h2>
                  <Button
                    disabled={!submitClicked}
                    onClick={() =>
                      history.push(
                        "/onboarding/" +
                          nextOnboardingPage("individual-legal", props.kycFlow)
                      )
                    }
                    className="m-auto d-block mt-4"
                    color={"primary"}
                  >
                    Proceed
                  </Button>
                </div>
              </Fragment>
            )}
          </Fade>
        </Col>
      </Row>
    </Fragment>
  );
}

export default withApiValue(OnboardingIndividualLegal, config);

OnboardingIndividualLegal.propTypes = {
  company: PropTypes.object,
  kycFlow: PropTypes.array,
  update: PropTypes.func,
};
