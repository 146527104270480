import { graphQLURL } from "../client";
import { buildMutation, callMutation } from "../mutation";
import PropTypes from "prop-types";

export async function updateIncorporationDocument(
  input,
  output,
  { context, errorHandler }
) {
  let file = input.file;
  let formData = new FormData();
  let operations = {
    query:
      "mutation ( $file: Upload ) { submitCompanyKyc ( input: { incorporationDocument: $file } ) { kyc { identifier } authorizationError { message } authenticationError { message } } }",
    variables: { file: null },
  };
  formData.append("operations", JSON.stringify(operations));
  formData.append("map", '{"nFile": ["variables.file"]}');
  formData.append("nFile", file);
  let response = await fetch(graphQLURL, {
    method: "POST",
    headers: {
      Authorization: `JWT ${context.token}`,
    },
    body: formData,
  });
  let data = await response.json();
  return errorHandler(data);
}

export async function updateBankStatementDocument(
  input,
  output,
  { context, errorHandler },
  statementNumber
) {
  let file = input.file;
  let formData = new FormData();
  let operations = {
    query: `mutation ( $file: Upload ) { submitCompanyKyc ( input: { ${statementNumber}: $file } ) { kyc { identifier } authorizationError { message } authenticationError { message } } }`,
    variables: { file: null },
  };
  formData.append("operations", JSON.stringify(operations));
  formData.append("map", '{"nFile": ["variables.file"]}');
  formData.append("nFile", file);
  let response = await fetch(graphQLURL, {
    method: "POST",
    headers: {
      Authorization: `JWT ${context.token}`,
    },
    body: formData,
  });
  let data = await response.json();
  return errorHandler(data);
}

export async function updateInvoiceDocument(
  input,
  output,
  { context, errorHandler }
) {
  let file = input.file;
  let formData = new FormData();
  let operations = {
    query:
      "mutation ( $file: Upload ) { submitCompanyKyc ( input: { customerInvoice: $file } ) { kyc { identifier } authorizationError { message } authenticationError { message } } }",
    variables: { file: null },
  };
  formData.append("operations", JSON.stringify(operations));
  formData.append("map", '{"nFile": ["variables.file"]}');
  formData.append("nFile", file);
  let response = await fetch(graphQLURL, {
    method: "POST",
    headers: {
      Authorization: `JWT ${context.token}`,
    },
    body: formData,
  });
  let data = await response.json();
  return errorHandler(data);
}

export async function updateArticleIncorporationDocument(
  input,
  output,
  { context, errorHandler }
) {
  let file = input.file;
  let formData = new FormData();
  let operations = {
    query:
      "mutation ( $file: Upload ) { submitCompanyKyc ( input: { articleOfIncorporation: $file } ) { kyc { identifier } authorizationError { message } authenticationError { message } } }",
    variables: { file: null },
  };
  formData.append("operations", JSON.stringify(operations));
  formData.append("map", '{"nFile": ["variables.file"]}');
  formData.append("nFile", file);
  let response = await fetch(graphQLURL, {
    method: "POST",
    headers: {
      Authorization: `JWT ${context.token}`,
    },
    body: formData,
  });
  let data = await response.json();
  return errorHandler(data);
}

export async function updateAmendmentsDocument(
  input,
  output,
  { context, errorHandler }
) {
  let file = input.file;
  let formData = new FormData();
  let operations = {
    query:
      "mutation ( $file: Upload ) { submitCompanyKyc ( input: { amendmentsDocument: $file } ) { kyc { identifier } authorizationError { message } authenticationError { message } } }",
    variables: { file: null },
  };
  formData.append("operations", JSON.stringify(operations));
  formData.append("map", '{"nFile": ["variables.file"]}');
  formData.append("nFile", file);
  let response = await fetch(graphQLURL, {
    method: "POST",
    headers: {
      Authorization: `JWT ${context.token}`,
    },
    body: formData,
  });
  let data = await response.json();
  return errorHandler(data);
}

export async function updateAprAndLenderUpload(
  input,
  output,
  { context, errorHandler }
) {
  let file = input.file;
  let formData = new FormData();
  let operations = {
    query:
      "mutation ( $file: Upload ) { submitCompanyKyc ( input: { aprOrLenderLicense: $file } ) { kyc { identifier } authorizationError { message } authenticationError { message } } }",
    variables: { file: null },
  };
  formData.append("operations", JSON.stringify(operations));
  formData.append("map", '{"nFile": ["variables.file"]}');
  formData.append("nFile", file);
  let response = await fetch(graphQLURL, {
    method: "POST",
    headers: {
      Authorization: `JWT ${context.token}`,
    },
    body: formData,
  });
  let data = await response.json();
  return errorHandler(data);
}

export function savePassbaseReferenceId(input, output, hooks) {
  const query = buildMutation(input, output, "savePassbaseReferenceId");
  return callMutation(query, hooks);
}

savePassbaseReferenceId.propTypes = {
  input: PropTypes.shape({
    referenceId: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function updatePassbaseReferenceId(input, output, hooks) {
  const query = buildMutation(input, output, "updatePassbaseReferenceId");
  return callMutation(query, hooks);
}

updatePassbaseReferenceId.propTypes = {
  input: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    referenceId: PropTypes.string.isRequired,
  }).isRequired,
  output: PropTypes.element.isRequired,
};

export function retryIdentityVerification(input, output, hooks) {
  const query = buildMutation(input, output, "retryIdentityVerification");
  return callMutation(query, hooks);
}
