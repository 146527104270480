import React, { Fragment, useEffect, useRef, useState } from "react";
import useForm from "../../../hooks/UseForms";
import { OwnersValidation } from "../../../modules/form_validation/OwnersValidation";
import Select from "react-select";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import PropTypes from "prop-types";
import {
  INDIVIDUAL,
  ORGANIZATION,
  addressProvinceOptions,
} from "../../../modules/Helpers";

function OnboardingOwnerForm(props) {
  const { values, setValues, errors, handleChange } = useForm(
    null,
    OwnersValidation
  );
  const [province, setProvince] = useState("");

  const refs = {
    legalName: useRef(),
    addressStreet: useRef(),
    percentOwned: useRef(),
    addressCity: useRef(),
    dateOfBirth: useRef(),
    occupation: useRef(),
    addressPostalCode: useRef(),
    addressProvince: useRef(),
  };

  useEffect(() => {
    refs.legalName.current.focus();
    if (props.addressProvince) {
      const filter = addressProvinceOptions.filter(
        (val) => val.value === props.addressProvince
      );
      if (filter.length > 0) {
        setProvince(filter[0]);
      } else {
        setProvince(filter);
      }
    }
    setValues({
      legalName: props.legalName,
      addressStreet: props.addressStreet,
      percentOwned: props.percentOwned,
      addressCity: props.addressCity,
      dateOfBirth: props.dateOfBirth,
      occupation: props.occupation,
      addressPostalCode: props.addressPostalCode,
      addressProvince: props.addressProvince,
    });
  }, []);

  useEffect(() => {
    props.setOwner(
      values.legalName,
      values.addressStreet,
      values.percentOwned,
      values.addressCity,
      values.dateOfBirth,
      values.occupation,
      values.addressPostalCode,
      props.addressProvince
    );
  }, [
    values.legalName,
    values.addressStreet,
    values.percentOwned,
    values.addressCity,
    values.dateOfBirth,
    values.occupation,
    values.addressPostalCode,
    values.addressProvince,
    props.addressProvince,
  ]);
  const handleProvinceChange = (e) => {
    setProvince(e);
    const newProvince = e.value;
    props.updateOwnerProvinceById(props.id, newProvince);
  };

  return (
    <Fragment>
      <div className="position-relative">
        <Label className="mt-4">Full legal name</Label>
        <FormGroup style={{ marginBottom: 12 }}>
          <Input
            name="legalName"
            type="text"
            innerRef={refs.legalName}
            value={values.legalName || ""}
            onChange={handleChange}
            invalid={!!errors.legalName}
            required
          />
          <FormFeedback>{errors.legalName}</FormFeedback>
        </FormGroup>
        <Row>
          <Col>
            <Label>Street address</Label>
            <FormGroup className="mb-3">
              <Input
                name="addressStreet"
                type="text"
                innerRef={refs.addressStreet}
                value={values.addressStreet || ""}
                onChange={handleChange}
                invalid={!!errors.addressStreet}
                required
              />
              <FormFeedback>{errors.addressStreet}</FormFeedback>
            </FormGroup>
          </Col>
          <Col sm="3">
            <Label>Ownership %</Label>
            <FormGroup className="mb-3">
              <Input
                name="percentOwned"
                type="number"
                min={0}
                max={100}
                onFocus={() => refs.percentOwned.current.select()}
                innerRef={refs.percentOwned}
                value={
                  props.companyType === ORGANIZATION
                    ? values.percentOwned || ""
                    : 100
                }
                onChange={handleChange}
                invalid={!!errors.percentOwned}
                required
                readOnly={props.companyType === INDIVIDUAL}
              />
              <FormFeedback>{errors.percentOwned}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>address city</Label>
            <FormGroup className="mb-3">
              <Input
                name="addressCity"
                type="text"
                innerRef={refs.addressCity}
                value={values.addressCity || ""}
                onChange={handleChange}
                invalid={!!errors.addressCity}
                required
              />
              <FormFeedback>{errors.addressCity}</FormFeedback>
            </FormGroup>
          </Col>
          <Col sm="6">
            <Label>Date of Birth</Label>
            <FormGroup className="mb-3">
              <Input
                name="dateOfBirth"
                type="date"
                innerRef={refs.dateOfBirth}
                value={values.dateOfBirth || ""}
                onChange={handleChange}
                invalid={!!errors.dateOfBirth}
                min="0001-01-01"
                max={new Date().toISOString().split("T")[0]}
                required
              />
              <FormFeedback>{errors.dateOfBirth}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <Label>Occupation</Label>
            <FormGroup className="mb-3">
              <Input
                name="occupation"
                type="text"
                innerRef={refs.occupation}
                value={values.occupation || ""}
                onChange={handleChange}
                invalid={!!errors.occupation}
                required
              />
              <FormFeedback>{errors.occupation}</FormFeedback>
            </FormGroup>
          </Col>
          <Col sm="6">
            <Label>Address postal code</Label>
            <FormGroup className="mb-3">
              <Input
                name="addressPostalCode"
                type="text"
                innerRef={refs.addressPostalCode}
                value={values.addressPostalCode || ""}
                onChange={handleChange}
                invalid={!!errors.addressPostalCode}
                required
              />
              <FormFeedback>{errors.addressPostalCode}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Address province</Label>
            <FormGroup>
              <Select
                onChange={handleProvinceChange}
                className="react-select default"
                classNamePrefix="react-select"
                name="addressProvince"
                value={province}
                options={addressProvinceOptions}
                placeholder="Select..."
                ref={refs.addressProvince}
              />
              <Input
                className="p-0 m-0"
                name="addressProvince"
                style={{ opacity: 0, height: 0 }}
                value={province}
                readOnly
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <Button
          size="sm"
          className="position-absolute btn btn-link"
          style={{ top: 15, right: -5 }}
          onClick={() => props.removeOwner(props.id)}
        >
          <i className="fas fa-times-circle text-muted" />
        </Button>
      </div>
    </Fragment>
  );
}

export default OnboardingOwnerForm;

OnboardingOwnerForm.propTypes = {
  id: PropTypes.number,
  legalName: PropTypes.string,
  addressStreet: PropTypes.string,
  percentOwned: PropTypes.number,
  addressCity: PropTypes.string,
  dateOfBirth: PropTypes.string,
  occupation: PropTypes.string,
  addressPostalCode: PropTypes.string,
  addressProvince: PropTypes.string,
  setOwner: PropTypes.func,
  removeOwner: PropTypes.func,
  updateOwnerProvinceById: PropTypes.func,
};
