/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { formattedDollarValue } from "../../../../modules/Helpers";
import { Button } from "reactstrap";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";

function BillingDetails(props) {
  const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#2f2f2f",
      color: "#ffffff",
      maxWidth: 285,
      fontSize: theme.typography.pxToRem(13),
      padding: 15,
    },
    arrow: {
      color: "#2f2f2f",
    },
  }))(Tooltip);
  return (
    <>
      <div>
        <div className="flex-row">
          <Button
            size="sm"
            color="primary"
            onClick={props.action}
            className="btn-simple pull-right"
          >
            {props.actionTitle}
          </Button>
          <h4 className="mb-0 d-inline-block">Upcoming Bill</h4>
        </div>
        <div className="flex-row">
          <h5
            className="text-primary font-weight-600 mb-2 text-uppercase"
            style={{ marginTop: "30px" }}
          >
            Billing Details
          </h5>
        </div>
        <div className="flex-row">
          <p className="d-inline-block text-muted mb-2">Billing Period</p>
          <p className="pull-right font-weight-600 mb-2">
            {" "}
            {moment(`${props.bill.year}-${props.bill.month}`).format(
              "MMMM YYYY"
            )}
          </p>
        </div>
        <div className="flex-row">
          <p className="d-inline-block text-muted mb-2">Billing Account</p>
          <p className="pull-right font-weight-600 mb-2">
            {props.bill?.owner?.company?.billingAccount && (
              <>
                {props.bill.owner.company.billingAccount?.institution} -{" "}
                {props.bill.owner.company.billingAccount?.title} -{" "}
                {
                  props.bill.owner.company.billingAccount?.caRoutingInfo
                    .accountNumber
                }
              </>
            )}
          </p>
        </div>
        <div className="flex-row">
          <p className="d-inline-block text-muted mb-2">Invoice Date</p>
          <p className="pull-right font-weight-600 mb-2">
            {" "}
            {moment(`${props.bill.year}-${props.bill.month + 1}`).format(
              "MMMM D, YYYY"
            )}
          </p>
        </div>
        <div className="flex-row">
          <p className="d-inline-block text-muted mb-2">Payment Volume</p>
          <p className="pull-right font-weight-600 mb-2">
            {formattedDollarValue(props.bill.amountTransacted)}
          </p>
        </div>
        <div className="flex-row">
          <p className="d-inline-block text-muted mb-2">Total Payments</p>
          <p className="pull-right font-weight-600 mb-2">
            {props.bill.numTransactions}
          </p>
        </div>
        <div className="flex-row">
          <h5 className="text-primary font-weight-600 mt-3 mb-2 text-uppercase">
            Payment Summary
          </h5>
        </div>
        <div className="flex-row">
          <p className="d-inline-block text-muted mb-2">NSFs</p>
          <CustomTooltip
            title={`${props.bill.numNsfs} NSFs totalling ${formattedDollarValue(
              props.bill.costNsfs
            )}`}
            placement="top"
            arrow
          >
            <p
              className="pull-right font-weight-600 mb-2"
              style={{ cursor: "pointer" }}
            >
              {formattedDollarValue(props.bill.costNsfs)}{" "}
              <span className="text-muted font-weight-400">
                ({props.bill.numNsfs})
              </span>
            </p>
          </CustomTooltip>
        </div>
        <div className="flex-row">
          <p className="d-inline-block text-muted mb-2">Internals</p>
          <CustomTooltip
            title={`${
              props.bill.numInternals
            } internals totalling ${formattedDollarValue(
              props.bill.costInternals
            )}`}
            placement="top"
            arrow
          >
            <p
              className="pull-right font-weight-600 mb-2"
              style={{ cursor: "pointer" }}
            >
              {formattedDollarValue(props.bill.costInternals)}{" "}
              <span className="text-muted font-weight-400">
                ({props.bill.numInternals})
              </span>
            </p>
          </CustomTooltip>
        </div>
        <div className="flex-row">
          <p className="d-inline-block text-muted mb-2">Payables</p>
          <CustomTooltip
            title={`${
              props.bill.numCredits
            } payables totalling ${formattedDollarValue(
              props.bill.costCredits
            )}`}
            placement="top"
            arrow
          >
            <p
              className="pull-right font-weight-600 mb-2"
              style={{ cursor: "pointer" }}
            >
              {formattedDollarValue(props.bill.costCredits)}{" "}
              <span className="text-muted font-weight-400">
                ({props.bill.numCredits})
              </span>
            </p>
          </CustomTooltip>
        </div>
        <div className="flex-row">
          <p className="d-inline-block text-muted mb-2">Receivables</p>
          <CustomTooltip
            title={`${
              props.bill.numDebits
            } receivables totalling ${formattedDollarValue(
              props.bill.costDebits
            )}`}
            placement="top"
            arrow
          >
            <p
              className="pull-right font-weight-600 mb-2"
              style={{ cursor: "pointer" }}
            >
              {formattedDollarValue(props.bill.costDebits)}{" "}
              <span className="text-muted font-weight-400">
                ({props.bill.numDebits})
              </span>
            </p>
          </CustomTooltip>
        </div>
        <div className="flex-row border-top border-background">
          <p className="d-inline-block text-muted mt-2 mb-2">Sub-Total</p>
          <CustomTooltip
            title={`${
              props.bill.numTransactions
            } payments totalling ${formattedDollarValue(props.bill.subtotal)}`}
            placement="top"
            arrow
          >
            <p
              className="pull-right font-weight-600 mt-2 mb-0"
              style={{ cursor: "pointer" }}
            >
              {formattedDollarValue(props.bill.subtotal)}{" "}
              <span className="text-muted font-weight-400 mb-0">
                ({props.bill.numTransactions})
              </span>
            </p>
          </CustomTooltip>
        </div>
      </div>
      <div className="flex-row">
        <p className="d-inline-block text-muted mb-2">Discount</p>
        <p className="pull-right font-weight-600 text-success mb-2">
          -${props.bill.availableCredit}
        </p>
      </div>
      <div className="flex-row">
        <p className="d-inline-block text-muted mb-2">Tax (5%)</p>
        <p className="pull-right font-weight-600 mb-2">${props.bill.tax}</p>
      </div>
      <div className="flex-row mt-1">
        <h4 className="d-inline-block font-weight-600 mb-0">Total</h4>
        <h3 className="pull-right font-weight-600 mb-0">
          {formattedDollarValue(props.bill.total)}
        </h3>
      </div>
    </>
  );
}

export default BillingDetails;

BillingDetails.propTypes = {
  bill: PropTypes.object.isRequired,
  action: PropTypes.func,
  actionTitle: PropTypes.string,
};
