import React from "react";
import { Card, Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUniversity } from "@fortawesome/free-solid-svg-icons";
import { getBankIcon } from "../../modules/Helpers";

function RecurringPlanPaidToCard(props) {
  return (
    <>
      <Row>
        <Col>
          <h4 className="font-weight-600 mt-0 mb-2">Paid to</h4>
        </Col>
      </Row>
      <Card className="details-card shadow-sm border">
        <Row className="details-row border-bottom">
          <Col>
            <h5>You</h5>
            <p>{props.recurringPlan.issuedBy?.company?.name}</p>
          </Col>
        </Row>
        <Row className="details-row">
          <Col>
            <div className="bank-logo">
              {props.recurringPlan.toBankAccount ? (
                <img
                  src={getBankIcon(
                    props.recurringPlan.toBankAccount.institution
                  )}
                  alt="Bank Logo"
                />
              ) : (
                <FontAwesomeIcon icon={faUniversity} />
              )}
            </div>
            <h5>Deposit account</h5>
            <p>
              {props.recurringPlan.toBankAccount ? (
                `${props.recurringPlan.toBankAccount.institution} - ${props.recurringPlan.toBankAccount.title} - ${props.recurringPlan.toBankAccount.caRoutingInfo.accountNumber}`
              ) : (
                <span className="text-muted font-italic">
                  Pending authorization
                </span>
              )}
            </p>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default RecurringPlanPaidToCard;
RecurringPlanPaidToCard.propTypes = {
  recurringPlan: PropTypes.object,
};
