import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Fade,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import PropTypes from "prop-types";
import useForm from "../../../hooks/UseForms";
import { profileFormValidation } from "../../../modules/form_validation/ProfileFormValidation";
import useGQL from "../../../api_client/UseGQL";
import { setUserProfile } from "../../../api_client/mutations/userProfile";
import { PulseLoader } from "react-spinners";
import { setCompanyProfile } from "../../../api_client/mutations/companyProfile";
import useImageUpload from "../../../hooks/UseImageUpload";
import defaultImage from "../../../assets/img/Company-Placeholder.png";
import { useToasts } from "react-toast-notifications";
import { trackEvent } from "../../../modules/analytics";
import { CustomTooltip, nextOnboardingPage } from "../../../modules/Helpers";
import { useHistory } from "react-router";
import { withApiValue } from "../../../modules/WithApiValue";
import Select from "react-select";
import { businessId } from "../../../modules/Helpers";
import { businessCategoryId } from "../../../modules/Helpers";
import successImage from "../../../assets/img/VerifiedCheckmark.png";
import { submitCompanyKyc } from "../../../api_client/mutations/kyc";
const config = {
  isOwner: true,
  company: {
    name: true,
    displayImage: true,
    companyType: true,
    fullName: true,
    validated: true,
    kyc: {
      sourceOfWealth: true,
      avgTransactionSize: true,
      avgMonthlyVolume: true,
      vopayBusinessTypeId: true,
      vopayBusinessTypeCategoryId: true,
      aprOrLenderLicense: true,
      updateRequired: true,
      resubmitted: true,
      refreshFields: true,
    },
  },
  kyc: { updateRequired: true },
  profile: { firstName: true, lastName: true },
};

function OnboardingCreateProfile(props) {
  const { addToast } = useToasts();
  const { values, handleChange, handleSubmit, errors, setValues } = useForm(
    submit,
    profileFormValidation
  );
  const [loading, setLoading] = useState(false);
  const [tooltipStatementIsOpen, setTooltipStatementIsOpen] = useState(false);
  const [tooltipPublicNameIsOpen, setTooltipPublicNameIsOpen] = useState(false);
  const [tooltipLogoIsOpen, setTooltipLogoIsOpen] = useState(false);
  const [structure, setStructure] = useState();
  const [categoryId, setCategoryId] = useState();
  const [businessStructure, setBusinessStructure] =
    useState(businessCategoryId);
  const [businessStructureVal, setBusinessStructureVal] = useState("");
  const [displayButton, setDisplayButton] = useState(false);

  let gqlHooks = useGQL();
  const history = useHistory();
  const refs = {
    firstName: useRef(null),
    lastName: useRef(null),
    structure: useRef(null),
    businessStructure: useRef(null),
    sourceOfWealth: useRef(null),
    avgTransactionSize: useRef(null),
    avgMonthlyVolume: useRef(null),
  };
  const fileInputRef = useRef(null);
  const fileInputRef2 = useRef(null);
  const {
    handleImageChange,
    handleImageSubmit,
    handleClick,
    imageState,
    setImageState,
  } = useImageUpload(
    fileInputRef,
    props.company
      ? props.company.displayImage
        ? props.company.displayImage
        : defaultImage
      : defaultImage
  );
  const AprAndLenderLicense = useImageUpload(
    fileInputRef2,
    defaultImage,
    "APRANDLENDER"
  );
  useEffect(() => {
    if (
      (businessStructureVal[0]?.category_id === 120 &&
        structure[0]?.id === 13) ||
      (businessStructureVal?.category_id === 120 && structure?.id === 13)
    ) {
      setDisplayButton(true);
    } else {
      setDisplayButton(false);
    }
  }, [businessStructureVal, businessStructure]);
  useEffect(() => {
    if (props.company) {
      if (props.company.kyc.vopayBusinessTypeId) {
        const filteredValue = businessId.filter(
          (business_type) =>
            business_type.id === props.company.kyc.vopayBusinessTypeId
        );
        setStructure(filteredValue);
        setCategoryId(filteredValue[0]?.id);
        if (props.company.kyc.vopayBusinessTypeCategoryId) {
          const filteredCategoryValue = businessCategoryId.filter(
            (business_type) =>
              business_type.category_id ===
              props.company.kyc.vopayBusinessTypeCategoryId
          );
          setBusinessStructureVal(filteredCategoryValue);
        }
      }
    }
    if (props.company.kyc.aprOrLenderLicense !== "") {
      AprAndLenderLicense.setImageState({
        file: { name: props.company.kyc.aprOrLenderLicense },
      });
    }

    trackEvent("onboardingSetProfile", {}, gqlHooks);
    refs.firstName.current.focus();

    let updateValues = props.company.kyc.refreshFields
      ? JSON.parse(props.company.kyc.refreshFields)["Profile"]
      : [];
    if (
      updateValues &&
      localStorage.getItem("profileUpdated") === "false" &&
      props.isOwner
    ) {
      for (const updateValue of updateValues) {
        switch (updateValue) {
          case "First name":
            props.profile.firstName = null;
            break;
          case "Last name":
            props.profile.lastName = null;
            break;
          case "Business name statement descriptor":
            props.company.name = null;
            break;
          case "Average transaction size":
            props.company.kyc.avgTransactionSize = null;
            break;
          case "Average monthly volume":
            props.company.kyc.avgMonthlyVolume = null;
            break;
          case "Public business name":
            props.company.fullName = null;
            break;
          case "Business type":
            setStructure(null);
            break;
          case "Business category":
            setBusinessStructureVal("");
            break;
          case "Source of wealth":
            props.company.kyc.sourceOfWealth = null;
            break;
          case "Public business logo":
            setImageState({
              file: null,
              imagePreviewUrl: defaultImage,
            });
            break;
          case "APR and lender license":
            AprAndLenderLicense.setImageState({
              file: null,
              imagePreviewUrl: defaultImage,
            });
            break;
          default:
            break;
        }
      }
    }

    setValues({ ...props.profile, ...props.company, ...props.company.kyc });
  }, [props.company]);

  async function submit() {
    localStorage.setItem("profileUpdated", "true");
    if (
      !(
        props.isOwner &&
        (props.company.kyc.updateRequired ||
          props.company.kyc.resubmitted ||
          !props.company.validated)
      )
    ) {
      setLoading(true);
      let input = {
        firstName: values.firstName,
        lastName: values.lastName,
      };
      let output = {
        profile: {
          firstName: true,
          lastName: true,
        },
      };
      let response;
      response = await setUserProfile(input, output, gqlHooks);
      if (response) {
        trackEvent("onboardingSetProfileName", response, gqlHooks);
        props.update(response);
      }

      history.push(
        "/onboarding/" + nextOnboardingPage("profile", props.kycFlow)
      );
      setLoading(false);

      return;
    }

    if (!structure) {
      addToast("Please select Business Type before continuing", {
        appearance: "warning",
        autoDismiss: true,
      });
      return;
    }

    if (!businessStructureVal) {
      addToast("Please select Business Category before continuing", {
        appearance: "warning",
        autoDismiss: true,
      });
      return;
    }

    if (
      (!AprAndLenderLicense.imageState.file &&
        businessStructureVal[0]?.category_id === 120 &&
        structure[0]?.id === 13) ||
      (!AprAndLenderLicense.imageState.file &&
        businessStructureVal?.category_id === 120 &&
        structure?.id === 13)
    ) {
      addToast("Lender license is required before continuing", {
        appearance: "warning",
        autoDismiss: true,
      });
      return;
    }

    setLoading(true);
    let input = {
      firstName: values.firstName,
      lastName: values.lastName,
    };
    let output = {
      profile: {
        firstName: true,
        lastName: true,
      },
    };
    let response;
    response = await setUserProfile(input, output, gqlHooks);
    if (response) {
      trackEvent("onboardingSetProfileName", response, gqlHooks);
      props.update(response);
    }
    input = {
      name: values.name,
      fullName: values.fullName,
    };
    output = {
      company: {
        name: true,
        fullName: true,
      },
    };
    response = await setCompanyProfile(input, output, gqlHooks);
    if (response) {
      trackEvent("onboardingSetCompanyName", response, gqlHooks);
      props.update(response);
    }

    input = {
      sourceOfWealth: values.sourceOfWealth ? values.sourceOfWealth : "",
      avgTransactionSize: values.avgTransactionSize,
      avgMonthlyVolume: values.avgMonthlyVolume,
      vopayBusinessTypeId: structure?.id,
      vopayBusinessTypeCategoryId: businessStructureVal?.category_id,
    };
    output = {
      kyc: {
        sourceOfWealth: true,
        avgTransactionSize: true,
        avgMonthlyVolume: true,
        vopayBusinessTypeId: true,
        vopayBusinessTypeCategoryId: true,
      },
    };
    response = await submitCompanyKyc(input, output, gqlHooks);
    if (response && response.kyc) {
      // setUpdateOrganizationLegalInfo(false);
      trackEvent("legalInformationSubmitted", {}, gqlHooks);
      response.company = { kyc: response.kyc };
      delete response.kyc;
      props.update(response);
    }
    if (imageState.file) {
      const imageSuccess = await handleImageSubmit();
      if (!imageSuccess) {
        addToast("Company logo upload failed", {
          appearance: "warning",
          autoDismiss: true,
        });
        setLoading(false);
        return;
      }
      trackEvent("onboardingUploadImage", { success: imageSuccess }, gqlHooks);
    }
    if (AprAndLenderLicense.imageState.file?.size < 1000000) {
      await AprAndLenderLicense.handleImageSubmit();
    }

    history.push("/onboarding/" + nextOnboardingPage("profile", props.kycFlow));
    setLoading(false);
  }
  function updateBusinessCategoryId(event) {
    setStructure(event);
    setCategoryId(event.id);
  }

  useEffect(() => {
    const filteredValue = businessCategoryId.filter(
      (value) => value.business_id === categoryId
    );
    setBusinessStructure(filteredValue);
  }, [structure, categoryId]);

  return (
    <Fragment>
      <Row className="mr-0 ml-0">
        <Col className="m-auto" style={{ maxWidth: 600 }}>
          <Fade>
            <h2 className="text-center mb-3 font-weight-600">
              Set up your profile
            </h2>
            <Form className="form" onSubmit={handleSubmit}>
              <Row>
                <Col sm="6">
                  <Label className="mt-2">First name</Label>
                  <FormGroup>
                    <Input
                      name="firstName"
                      type="text"
                      innerRef={refs.firstName}
                      value={values.firstName}
                      invalid={!!errors.firstName}
                      onChange={handleChange}
                      required
                    />
                    <FormFeedback>{errors.firstName}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm="6">
                  <Label className="mt-2">Last name</Label>
                  <FormGroup>
                    <Input
                      name="lastName"
                      type="text"
                      innerRef={refs.lastName}
                      value={values.lastName}
                      invalid={!!errors.lastName}
                      onChange={handleChange}
                      required
                    />
                    <FormFeedback>{errors.lastName}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              {props.isOwner &&
              (props.company.kyc.updateRequired ||
                props.company.kyc.resubmitted ||
                !props.company.validated) ? (
                <div>
                  <Label className="mt-2">
                    Business name statement descriptor
                  </Label>
                  <CustomTooltip
                    title=" Your business name statement descriptor is used on receipts
                      and bank statements (must be less than 15 characters)."
                    placement="top"
                    arrow
                    open={tooltipStatementIsOpen}
                    onOpen={() => setTooltipStatementIsOpen(true)}
                    onClose={() => setTooltipStatementIsOpen(false)}
                    leaveTouchDelay={10000}
                  >
                    <Button
                      onClick={() =>
                        setTooltipStatementIsOpen(!tooltipStatementIsOpen)
                      }
                      variant="contained"
                      className="btn-link tooltip-button"
                    >
                      <i className="fas fa-info-circle" />
                    </Button>
                  </CustomTooltip>
                  <FormGroup>
                    <Input
                      name="name"
                      type="text"
                      value={values.name}
                      invalid={!!errors.name}
                      onChange={handleChange}
                      required
                    />
                    <FormFeedback>{errors.name}</FormFeedback>
                  </FormGroup>
                  <Label className="mt-2">Average transaction size</Label>
                  <FormGroup>
                    <Input
                      name="avgTransactionSize"
                      type="number"
                      step="0.01"
                      innerRef={refs.avgTransactionSize}
                      value={values.avgTransactionSize}
                      invalid={!!errors.avgTransactionSize}
                      onChange={handleChange}
                      required
                    />
                    <FormFeedback>{errors.avgTransactionSize}</FormFeedback>
                  </FormGroup>

                  <Label className="mt-2">Average monthly volume</Label>
                  <FormGroup>
                    <Input
                      name="avgMonthlyVolume"
                      type="number"
                      step="0.01"
                      innerRef={refs.avgMonthlyVolume}
                      value={values.avgMonthlyVolume}
                      invalid={!!errors.avgMonthlyVolume}
                      onChange={handleChange}
                      required
                    />
                    <FormFeedback>{errors.avgMonthlyVolume}</FormFeedback>
                  </FormGroup>
                  <Label className="mt-2">Public business name</Label>
                  <CustomTooltip
                    title="This name will be used in emails and content displayed to you and your contacts."
                    placement="top"
                    arrow
                    open={tooltipPublicNameIsOpen}
                    onOpen={() => setTooltipPublicNameIsOpen(true)}
                    onClose={() => setTooltipPublicNameIsOpen(false)}
                    leaveTouchDelay={10000}
                  >
                    <Button
                      onClick={() =>
                        setTooltipPublicNameIsOpen(!tooltipPublicNameIsOpen)
                      }
                      variant="contained"
                      className="btn-link tooltip-button"
                    >
                      <i className="fas fa-info-circle" />
                    </Button>
                  </CustomTooltip>
                  <FormGroup>
                    <Input
                      name="fullName"
                      type="text"
                      value={values.fullName}
                      invalid={!!errors.fullName}
                      onChange={handleChange}
                      required
                    />
                    <FormFeedback>{errors.fullName}</FormFeedback>
                  </FormGroup>
                  <Label>Business type</Label>
                  <FormGroup>
                    <Select
                      onChange={updateBusinessCategoryId}
                      name="structure"
                      value={structure}
                      options={businessId}
                    />
                    <Input
                      className="p-0 m-0"
                      name="structure"
                      style={{ opacity: 0, height: 0 }}
                      value={structure}
                      readOnly
                      required
                    />
                    <FormFeedback>{errors.structure}</FormFeedback>
                  </FormGroup>
                  <Label>Business category</Label>
                  <FormGroup>
                    <Select
                      onChange={setBusinessStructureVal}
                      name="businessStructure"
                      value={businessStructureVal}
                      options={businessStructure}
                    />
                    <Input
                      className="p-0 m-0"
                      name="businessStructure"
                      style={{ opacity: 0, height: 0 }}
                      value={businessStructureVal}
                      readOnly
                      required
                    />
                    <FormFeedback>{errors.businessStructure}</FormFeedback>
                  </FormGroup>
                  <Label className="mt-2">
                    Source of wealth{" "}
                    {!displayButton ? (
                      <b className="text-muted font-weight-400 text-sm">
                        (Optional)
                      </b>
                    ) : null}
                  </Label>
                  <FormGroup>
                    <Input
                      name="sourceOfWealth"
                      type="text"
                      innerRef={refs.sourceOfWealth}
                      value={values.sourceOfWealth}
                      invalid={!!errors.sourceOfWealth}
                      onChange={handleChange}
                      required={displayButton}
                    />
                    <FormFeedback>{errors.sourceOfWealth}</FormFeedback>
                  </FormGroup>
                  <Label className="mt-2">
                    Public business logo{" "}
                    <b className="text-muted font-weight-400 text-sm">
                      (Optional; Used on receipts and in-app branding)
                    </b>
                  </Label>
                  <CustomTooltip
                    title="Image must be 32px by 32px, less than 1mb"
                    placement="top"
                    arrow
                    open={tooltipLogoIsOpen}
                    onOpen={() => setTooltipLogoIsOpen(true)}
                    onClose={() => setTooltipLogoIsOpen(false)}
                    leaveTouchDelay={10000}
                  >
                    <Button
                      onClick={() => setTooltipLogoIsOpen(!tooltipLogoIsOpen)}
                      variant="contained"
                      className="btn-link tooltip-button"
                    >
                      <i className="fas fa-info-circle" />
                    </Button>
                  </CustomTooltip>
                  <div className="mb-4">
                    <input
                      type="file"
                      onChange={handleImageChange}
                      ref={fileInputRef}
                      accept="image/*"
                      className="d-none"
                    />
                    <Button
                      onClick={handleClick}
                      type="button"
                      className="btn-link text-primary font-weight-600"
                    >
                      + Upload Logo
                    </Button>
                    <img
                      height={30}
                      src={imageState.imagePreviewUrl}
                      alt="..."
                    />
                  </div>
                  {displayButton ? (
                    <div>
                      <Label>APR and lender license</Label>
                      <br />
                      <input
                        type="file"
                        onChange={AprAndLenderLicense.handleImageChange}
                        ref={fileInputRef2}
                        accept="image/*,application/pdf"
                        className="d-none"
                      />
                      <Button
                        onClick={AprAndLenderLicense.handleClick}
                        type="button"
                        className="btn-simple btn-primary btn-sm"
                      >
                        + Upload Document
                      </Button>{" "}
                      <img
                        height={30}
                        src={
                          AprAndLenderLicense.imageState.file
                            ? successImage
                            : AprAndLenderLicense.imageState.imagePreviewUrl
                        }
                        alt="..."
                      />
                      <span className="text-xs text-primary font-weight-bold  ">
                        <br />
                        {AprAndLenderLicense.imageState?.file?.name}
                      </span>
                    </div>
                  ) : null}
                </div>
              ) : null}
              <div>
                <Button
                  className="mt-4"
                  type="submit"
                  block
                  disabled={loading}
                  color="primary"
                >
                  {loading ? (
                    <PulseLoader color="white" size={10} />
                  ) : (
                    "Proceed"
                  )}
                </Button>
              </div>
            </Form>
          </Fade>
        </Col>
      </Row>
    </Fragment>
  );
}

export default withApiValue(OnboardingCreateProfile, config);

OnboardingCreateProfile.propTypes = {
  isOwner: PropTypes.bool,
  company: PropTypes.object,
  kyc: PropTypes.object,
  profile: PropTypes.object,
  update: PropTypes.func,
  kycFlow: PropTypes.array,
};
