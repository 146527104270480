import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import BillingPAD from "../../../components/PAD/BillingPAD";

import { Button, Col, Row } from "reactstrap";
import { useToasts } from "react-toast-notifications";
import { useLocation } from "react-router-dom";
import useGQL from "../../../api_client/UseGQL";
import { assignBankAccountAsBilling } from "../../../api_client/mutations/bankAccount";
import { PulseLoader } from "react-spinners";

function OnboardingAddBilling(props) {
  const [account, setAccount] = useState({
    caRoutingInfo: { accountNumber: "" },
  });
  const [signaturePAD, setSignaturePAD] = useState(false);
  const [signaturePADTime, setSignaturePADTime] = useState(null);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const location = useLocation();
  const gqlHooks = useGQL();

  useEffect(() => {
    if (location.state) {
      setAccount(location.state.account);
    }
    if (props.account) {
      setAccount(props.account);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const agreeToPAD = () => {
    // / 1000 to get seconds instead of miliseconds
    const time = new Date().getTime() / 1000;
    setSignaturePAD(!signaturePAD);
    setSignaturePADTime(Math.floor(time));
  };

  async function submit() {
    setLoading(true);
    let output = {
      ok: true,
    };
    const response = await assignBankAccountAsBilling(
      {
        bankAccountId: account.identifier,
        signaturePad: signaturePAD,
        signaturePadTime: signaturePADTime,
      },
      output,
      gqlHooks
    );
    setLoading(false);
    if (response.ok) {
      props.update({
        company: { billingAccount: { identifier: account.identifier } },
      });
      addToast("Billing added successfully", {
        appearance: "success",
        autoDismiss: true,
      });
      props.setComplete(true);
    }
  }

  function accountName() {
    if (account.institution === "") {
      return (
        <p className="text-muted text-center mb-4">
          Your bank account will be invoiced monthly.
        </p>
      );
    } else {
      return (
        <p className="text-muted text-center mb-4">
          Your bank account{" "}
          <b>{`${account.institution} - ${
            account.title
          } - ${account.caRoutingInfo.accountNumber.slice(-3)}`}</b>{" "}
          will be invoiced monthly.
        </p>
      );
    }
  }

  return (
    <Fragment>
      <Row className="mr-0 ml-0">
        <Col className="m-auto" style={{ maxWidth: 800 }}>
          <h2 className="text-center mb-3 font-weight-600">
            Set your billing account
          </h2>
          {accountName()}
          <BillingPAD
            agreeToPAD={agreeToPAD}
            account={account}
            companyName={props.name}
            signaturePAD={signaturePAD}
          />
          <Button
            disabled={!signaturePAD}
            onClick={submit}
            block
            className="mt-4"
            color={signaturePAD ? "primary" : "primary"}
          >
            {loading ? <PulseLoader color="white" size={10} /> : "Set billing"}
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
}

export default OnboardingAddBilling;

OnboardingAddBilling.propTypes = {
  setComplete: PropTypes.func,
  account: PropTypes.object,
  name: PropTypes.string,
  update: PropTypes.func,
};

OnboardingAddBilling.defaultProps = {};
