import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { createBankAccountManual } from "../../../api_client/mutations/bankAccount";
import ManualBankForm from "../../admin/settings/banks/manual/ManualBankForm";
import useForm from "../../../hooks/UseForms";
import { AddBankAccountValidation } from "../../../modules/form_validation/AddBankAccountValidation";
import useGQL from "../../../api_client/UseGQL";
import { Button, Col, Container, Row } from "reactstrap";
import confirmRoutingAlert from "../../../components/modals/ConfirmRouting";
import { useSimpleContext } from "../../../contexts/SimpleContext";
import BankConnectedStep from "../steps/BankConnectedStep";
import { PulseLoader } from "react-spinners";
import { useToasts } from "react-toast-notifications";
import PaymentBackTitle from "../components/PaymentBackTitle";
import { trackEvent } from "../../../modules/analytics";
import { formatPostalCode } from "../../../modules/Helpers";

function ManualEntryMethod(props) {
  const [state, setState] = useSimpleContext();
  const { values, setValues, handleChange, handleSubmit, errors, setErrors } =
    useForm(confirm, AddBankAccountValidation);
  const [hideAccountNumber, setHideAccountNumber] = useState(false);
  const [confirmModal, setConfirmModal] = useState(null);
  const [bankConnected, setBankConnected] = useState(false);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  let gqlHooks = useGQL();
  const refs = {
    institutionNumber: useRef(null),
    transitNumber: useRef(null),
    accountNumber: useRef(null),
    title: useRef(null),
    institution: useRef(null),
    holderName: useRef(null),
    holderEmail: useRef(null),
    holderAddress: useRef(null),
    holderAddressPostalCode: useRef(null),
    holderAddressCity: useRef(null),
  };

  ManualEntryMethod.defaultProps = {
    collectBankInfoOnly: false,
  };

  useEffect(() => {
    if (props.collectBankInfoOnly === true) {
      trackEvent(
        "supplierInviteBankConnection",
        {
          contactId: props.contactIdentifier,
        },
        gqlHooks
      );
    } else {
      if (state.transaction) {
        if (state.transaction.direction === "CREDIT") {
          trackEvent(
            "creditManualEntry",
            {
              transactionId: state.transaction.identifier,
              companyId: state.transaction.company?.identifier,
              contactId: state.transaction.contact.identifier,
            },
            gqlHooks
          );
        } else {
          trackEvent(
            "debitManualEntry",
            {
              transactionId: state.transaction.identifier,
              companyId: state.transaction.company?.identifier,
              contactId: state.transaction.contact.identifier,
            },
            gqlHooks
          );
        }
      } else {
        trackEvent(
          "recurringManualEntry",
          {
            recurringId: state.recurringPlan.identifier,
            companyId: state.recurringPlan.company.identifier,
            contactId: state.recurringPlan.contact.identifier,
          },
          gqlHooks
        );
      }
    }
  }, []);

  async function handleNext() {
    setLoading(true);
    let output = {
      bankAccount: {
        identifier: true,
        caRoutingInfo: {
          accountNumber: true,
        },
        institution: true,
        previouslySaved: true,
        bankAccountToken: true,
      },
    };
    let input = {
      bankAccountCa: {
        transitNumber: values.transitNumber,
        institutionNumber: values.institutionNumber,
        accountNumber: values.accountNumber,
        institution: values.institution,
        title: values.title,
        accountType: values.accountType,
        holderName: values.holderName,
        holderAddress: values.holderAddress,
        holderAddressPostalCode: formatPostalCode(
          values.holderAddressPostalCode
        ),
        holderAddressCity: values.holderAddressCity,
        holderEmail: values.holderEmail,
        holderAddressCountry: "CA",
      },
      contactId: props.contactIdentifier,
    };
    let response = await createBankAccountManual(input, output, gqlHooks);
    if (response && response.bankAccount) {
      setState({ ...state, bankAccount: response.bankAccount });
      setBankConnected(true);
      setLoading(false);
    } else {
      addToast("Error connecting bank account", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }

  const changeConfirmModal = (modal = null, hide = false) => {
    setHideAccountNumber(hide);
    setConfirmModal(modal);
  };

  function submit() {
    changeConfirmModal();
    handleNext();
  }

  function confirm() {
    if (values.holderAddressPostalCode) {
      values.holderAddressPostalCode = values.holderAddressPostalCode.replace(
        /-|\s/g,
        ""
      );
    }

    let errs = {};
    Object.keys(refs)
      .reverse()
      .forEach(function (key) {
        if (refs[key].current && (!values[key] || values[key] === "")) {
          errs[key] = "This field is required";
          refs[key].current.focus();
        }
      });
    setErrors(errs);
    if (Object.entries(errs).length === 0) {
      confirmRoutingAlert(
        changeConfirmModal,
        submit,
        values.accountNumber,
        setErrors,
        refs.accountNumber
      );
    }
  }

  if (bankConnected) {
    return (
      <BankConnectedStep
        collectBankInfoOnly={props.collectBankInfoOnly}
        contactIdentifier={props.contactIdentifier}
        setBankConnected={setBankConnected}
        setBankMethod={props.setBankMethod}
      />
    );
  } else {
    return (
      <Fragment>
        {confirmModal}
        <PaymentBackTitle
          title={
            state?.transaction?.direction === "DEBIT"
              ? "Pay via Bank Transfer"
              : "Accept via Bank Transfer"
          }
          backAction={() => props.setBankMethod("")}
        />
        <Container className="bank-method-wrapper">
          <Row>
            <Col className="mb-3">
              <span className="text-xl font-weight-600">
                Enter bank account details manually
              </span>{" "}
              <br />
              <span className="text-sm">
                OR{" "}
                <u
                  className="text-primary cursor-pointer"
                  onClick={() => props.setBankMethod("login")}
                >
                  login with your bank
                </u>{" "}
                to retrieve these account details automatically.
              </span>
            </Col>
          </Row>
          <ManualBankForm
            refs={refs}
            values={values}
            setValues={setValues}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            errors={errors}
            hideAccountNumber={hideAccountNumber}
          />
          <Button
            block
            className="btn-primary btn-lg mb-4 mt-0"
            type="submit"
            onClick={confirm}
          >
            {loading ? (
              <PulseLoader color="white" size={10} />
            ) : (
              "Confirm account details"
            )}
          </Button>
        </Container>
      </Fragment>
    );
  }
}

export default ManualEntryMethod;

ManualEntryMethod.propTypes = {
  setBankMethod: PropTypes.func,
};
