import { useEffect, useState } from "react";
import { currentUser } from "../api_client/queries/user";
import useGQL from "../api_client/UseGQL";
import { useAppValue } from "../contexts/Context";
import { getKYCFlow } from "../modules/Helpers";

function useApiValue(data = null) {
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(true);
  const [kycFlow, setKYCFlow] = useState([]);
  const gqlHooks = useGQL();
  const [context, dispatch] = useAppValue();

  useEffect(() => {
    if (data !== null) {
      let stateOutput = { ...data };
      let stateOutputFlow = {
        isOwner: true,
        kyc: {
          updateRequired: true,
          resubmitted: true,
        },
        company: {
          companyType: true,
          kyc: {
            updateRequired: true,
            resubmitted: true,
            refreshFields: true,
          },
        },
      };

      currentUser({}, stateOutput, gqlHooks).then((response) => {
        if (response) {
          setValues(response.data);
        }

        currentUser({}, stateOutputFlow, gqlHooks).then((response) => {
          if (response) {
            const data = response.data;
            let updateStatus =
              data.kyc.updateRequired ||
              data.company.kyc.updateRequired ||
              data.kyc.resubmitted ||
              data.company.kyc.resubmitted;

            setKYCFlow(getKYCFlow(data, updateStatus));
          }

          setLoading(false);
        });
      });
    }
  }, []);

  useEffect(() => {
    function deepMerge(target, ...sources) {
      if (!sources.length) return target;
      const source = sources.shift();

      if (isObject(target) && isObject(source)) {
        for (const key in source) {
          if (target[key]) {
            if (isObject(source[key])) {
              deepMerge(target[key], source[key]);
            } else {
              Object.assign(target, { [key]: source[key] });
            }
          }
        }
      }

      deepMerge(target, ...sources);
    }

    function isObject(item) {
      return item && typeof item === "object" && !Array.isArray(item);
    }

    let valuesCopy = structuredClone(values);
    deepMerge(valuesCopy, context.updates);
    setValues(valuesCopy);
  }, [context.updates]);

  function update(newData) {
    dispatch({ type: "setUpdates", updates: newData });
  }

  return { values, kycFlow, setKYCFlow, update, loading, setLoading };
}

export default useApiValue;
