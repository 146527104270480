import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Button, FormFeedback, FormGroup, Input } from "reactstrap";
import useForm from "../../../hooks/UseForms";
import { saveBankModalFormValidation } from "../../../modules/form_validation/BankInfoModalFormValidation";
import useGQL from "../../../api_client/UseGQL";
import { verifyEmail } from "../../../modules/Helpers";
import { useSimpleContext } from "../../../contexts/SimpleContext";
import {
  bankAccountEmailMfa,
  bankAccountTextMfa,
  retrieveSavedBankAccounts,
  saveBankAccount,
} from "../../../api_client/mutations/bankAccount";
import { useHistory } from "react-router";
import { useToasts } from "react-toast-notifications";
import { trackEvent } from "../../../modules/analytics";

function EnterMFACodeForm(props) {
  const { values, handleChange, handleSubmit, waitErrors, errors } = useForm(
    submit,
    saveBankModalFormValidation
  );
  const [loading, setLoading] = useState(false);
  const gqlHooks = useGQL();
  const [state, setState] = useSimpleContext();
  const history = useHistory();
  const { addToast } = useToasts();

  async function submit() {
    setLoading(true);
    let response;
    let input;
    let output;

    if (props.type === "save") {
      output = {
        bankAccount: {
          identifier: true,
          createdAt: true,
          updatedAt: true,
          caRoutingInfo: {
            institutionNumber: true,
            accountNumber: true,
          },
          institution: true,
          title: true,
          accountType: true,
          state: true,
        },
      };
      if (verifyEmail(state.contactMethod)) {
        input = {
          email: state.contactMethod,
          code: values.code || "0",
          bankAccountToken: state.bankAccount.bankAccountToken,
        };
      } else {
        input = {
          phone: state.contactMethod,
          code: values.code || "0",
          bankAccountToken: state.bankAccount.bankAccountToken,
        };
      }

      response = await saveBankAccount(input, output, gqlHooks);
      if (response?.bankAccount) {
        props.onBankAccountSaved?.();
        addToast("Bank account has been saved :)", {
          appearance: "success",
          autoDismiss: true,
        });
        props.toggle();
        if (props.collectBankInfoOnly === true) {
          trackEvent(
            "supplierInviteBankConnection",
            {
              contactId: props.contactIdentifier,
            },
            gqlHooks
          );
        } else {
          if (state.transaction) {
            if (state.transaction.direction === "CREDIT") {
              trackEvent(
                "creditBankAccountSaved",
                {
                  transactionId: state.transaction.identifier,
                  companyId: state.transaction.company?.identifier,
                  contactId: state.transaction.contact.identifier,
                },
                gqlHooks
              );
            } else {
              trackEvent(
                "debitBankAccountSaved",
                {
                  transactionId: state.transaction.identifier,
                  companyId: state.transaction.company?.identifier,
                  contactId: state.transaction.contact.identifier,
                },
                gqlHooks
              );
            }
          } else {
            trackEvent(
              "recurringBankAccountSaved",
              {
                recurringId: state.recurringPlan.identifier,
                companyId: state.recurringPlan.company.identifier,
                contactId: state.recurringPlan.contact.identifier,
              },
              gqlHooks
            );
          }
        }
        history.push({ search: "" });
      }
    } else {
      output = {
        bankAccounts: {
          identifier: true,
          createdAt: true,
          updatedAt: true,
          caRoutingInfo: {
            institutionNumber: true,
            accountNumber: true,
          },
          institution: true,
          title: true,
          accountType: true,
          state: true,
          bankAccountToken: true,
        },
      };
      if (verifyEmail(state.contactMethod)) {
        input = {
          email: state.contactMethod,
          code: values.code || "0",
        };
      } else {
        input = {
          phone: state.contactMethod,
          code: values.code || "0",
        };
      }
      response = await retrieveSavedBankAccounts(input, output, gqlHooks);
      if (response && response.bankAccounts) {
        setState({ ...state, bankAccounts: response.bankAccounts });
        props.setListAccounts(true);
      }

      if (response) {
        // TODO
        console.log(response);
      }
    }
    setLoading(false);
  }

  async function resend() {
    setLoading(true);
    let input;
    let response;
    let output = {
      ok: true,
    };
    if (verifyEmail(state.contactMethod)) {
      input = { email: state.contactMethod };
      response = await bankAccountEmailMfa(input, output, gqlHooks);
      if (response && response.ok) {
        addToast(
          "A new verification code has been sent, please allow 30 seconds before sending another code",
          {
            appearance: "success",
            autoDismiss: true,
          }
        );
      }
    } else {
      input = { phone: state.contactMethod };
      response = await bankAccountTextMfa(input, output, gqlHooks);
      if (response && response.ok) {
        addToast(
          "A new verification code has been sent, please allow 30 seconds before sending another code",
          {
            appearance: "success",
            autoDismiss: true,
          }
        );
      }
    }
    setLoading(false);
  }
  return (
    <Fragment>
      <h4>Your saved account details</h4>
      <p>We&apos;ve sent a confirmation code to:</p>
      <h4 className="text-center mt-3 text-xl mb-0">{state.contactMethod}</h4>
      <Button
        className="text-primary text-underline btn-link m-auto d-block pb-3 pt-1"
        onClick={() => props.setEnterMfa(false)}
      >
        Use a different number or email
      </Button>
      <p>
        When you&apos;ve received your code, enter it here to retrieve your
        saved payment details.
      </p>
      <div className="mt-2">
        <label>Confirmation code</label>
        <FormGroup>
          <Input
            aria-label="Confirmation code"
            bsSize="lg"
            className="rounded"
            name="code"
            type="text"
            value={values.code || ""}
            onChange={handleChange}
            invalid={!!waitErrors.code || !!errors.code}
            required
          />
          <FormFeedback>{errors.code}</FormFeedback>
        </FormGroup>

        <Button
          disabled={loading || !values.code}
          onClick={handleSubmit}
          block
          className="btn-primary btn-lg text-white"
        >
          Enter confirmation code
        </Button>
      </div>
      <div className="mt-3">
        <Button
          disabled={loading}
          onClick={resend}
          block
          className="btn-simple btn-primary mb-4"
        >
          Resend confirmation code
        </Button>
      </div>
    </Fragment>
  );
}

export default EnterMFACodeForm;

EnterMFACodeForm.propTypes = {
  setEnterMfa: PropTypes.func,
  type: PropTypes.string,
  setListAccounts: PropTypes.func,
  onBankAccountSaved: PropTypes.func,
  toggle: PropTypes.func.isRequired,
};
