import React, { Fragment } from "react";
import { Col, Row } from "reactstrap";
import { useSimpleContext } from "../../../contexts/SimpleContext";
import {
  convertToDate,
  CustomTooltip,
  formatDate,
} from "../../../modules/Helpers";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useToasts } from "react-toast-notifications";
import {
  CreditCardAccount,
  WithdrawalAccount,
} from "../components/WithdrawalAccount";
import { PlatformFee } from "../components/PlatformFee";

function RecurringPaymentInfo() {
  const [state] = useSimpleContext();
  const { addToast } = useToasts();

  const copyIdToClipboard = () => {
    addToast("Payment id copied to clipboard", {
      appearance: "success",
      autoDismiss: true,
    });
  };
  return (
    <Fragment>
      <div className="payment-info">
        {state.recurringPlan.active && (
          <Row>
            <Col>
              <h5> Next payment</h5>
              <p className="text-success">
                {convertToDate(state.recurringPlan.nextRun)}
              </p>
            </Col>
          </Row>
        )}
        {state.recurringPlan.customerCreditCard ? (
          <Row>
            <Col>
              <CreditCardAccount
                customerCreditCard={state.recurringPlan.customerCreditCard}
              />
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col>
                <h5>Paid from</h5>
                <p>
                  {state.recurringPlan.fromBankAccount ? (
                    state.recurringPlan.contact.email
                  ) : (
                    <span className="text-muted font-italic">
                      Pending authorization
                    </span>
                  )}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <WithdrawalAccount
                  fromBankAccount={state.recurringPlan.fromBankAccount}
                />
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col>
            {state.recurringPlan.customerCreditCard && (
              <PlatformFee amount={state.recurringPlan.amount} />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Paid to</h5>
            <p>{state.recurringPlan.company.name}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Deposit account</h5>
            <p>
              {state.recurringPlan.toBankAccount ? (
                `${state.recurringPlan.toBankAccount.institution} - ${state.recurringPlan.toBankAccount.title} - ${state.recurringPlan.toBankAccount.caRoutingInfo.accountNumber}`
              ) : (
                <span className="text-muted font-italic">
                  Pending authorization
                </span>
              )}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Plan id</h5>
            <p>
              {" "}
              <CustomTooltip title="Click to copy" placement="top" arrow>
                <CopyToClipboard
                  onCopy={() => copyIdToClipboard()}
                  text={state.recurringPlan.identifier}
                >
                  <span style={{ cursor: "pointer" }}>
                    {state.recurringPlan.identifier}
                  </span>
                </CopyToClipboard>
              </CustomTooltip>
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Start date</h5>
            <p className={`${state.recurringPlan.expired && "text-danger"}`}>
              {formatDate(state.recurringPlan.start)}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>End date</h5>
            <p>
              {state.recurringPlan.end
                ? formatDate(state.recurringPlan.end)
                : "Continue until cancelled"}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Statement</h5>
            <p>{state.recurringPlan.statement}</p>
          </Col>
        </Row>
        {state.recurringPlan.description && (
          <Row>
            <Col>
              <h5>Notes</h5>
              <p className="font-italic">
                <q>{state.recurringPlan.description}</q>
              </p>
            </Col>
          </Row>
        )}
      </div>
    </Fragment>
  );
}

export default RecurringPaymentInfo;

RecurringPaymentInfo.propTypes = {};
