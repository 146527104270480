import { host } from "api_client/client";
import { useCreditCardHandler } from "./hooks/creditCardHandler";
import axios from "axios";
import { useScript } from "hooks/useScript";
import { convergeLightboxHostLib } from "modules/Helpers";
import { addPathToUrl } from "../../../api_client/utils";

function useConvergeMethod(setErrorMsg) {
  const { createConvergeCustomer } = useCreditCardHandler(setErrorMsg);
  useScript("https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js");
  useScript(convergeLightboxHostLib);

  // TODO: get this from db

  function getCustomerCreditCard(
    setLoading,
    setCustomerCreditCard,
    name,
    convergeMerchantAccount
  ) {
    var tokenRequest = {};
    try {
      tokenRequest = {
        merchant_id: convergeMerchantAccount.merchantId,
        user_id: convergeMerchantAccount.apiUser.userId,
        pin: convergeMerchantAccount.pin,
      };
    } catch (error) {
      // when any of the above attributes is unable to access or resolves to null
      setErrorMsg(
        "There seems to be something wrong with the merchant's account configuration, please reach out to Jetpay support so they can resolve this."
      );
      return;
    }

    const callbacks = {
      onError: (error) => {
        // Show error to your customer.
        setErrorMsg(error);
        setLoading(false);
      },
      onCancelled: () => {
        setLoading(false);
      },
      onDeclined: () => {
        setLoading(false);
      },
      onApproval: async (response) => {
        const ccToken = response?.ssl_token;
        const brand = response?.ssl_card_short_description;
        const masked_card_number = response?.ssl_card_number;
        response = await createConvergeCustomer(
          ccToken,
          brand,
          masked_card_number,
          name,
          convergeMerchantAccount.merchantId
        );
        setCustomerCreditCard(response?.customerCreditCard);
        setLoading(false);
      },
    };
    try {
      axios
        .post(
          addPathToUrl(host, "/converge/converge-lightbox-token"),
          tokenRequest
        )
        .then((response) => {
          let token = response.data.lightbox_session_token;
          window.PayWithConverge.open(
            {
              ssl_txn_auth_token: token, //lightbox token,
            },
            callbacks
          );
        });
    } catch (error) {
      console.error(error);
    }
  }

  return { getCustomerCreditCard };
}

export default useConvergeMethod;
