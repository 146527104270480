import React, { useEffect, useState } from "react";
import MobileStepper from "@material-ui/core/MobileStepper";
import { useLocation } from "react-router";

function OnboardingProgressBar() {
  const [activeStep, setActiveStep] = useState(0);
  const location = useLocation();

  useEffect(() => {
    setActiveStep(findStep());
  }, [location.pathname]);

  function findStep() {
    switch (location.pathname) {
      case "/onboarding/concierge":
        return 1;
      case "/onboarding/business-type":
        return 1;
      case "/onboarding/profile":
        return 2;
      case "/onboarding/individual-legal":
        return 3;
      case "/onboarding/organization-legal":
        return 3;
      case "/onboarding/owners":
        return 4;
      case "/onboarding/identity":
        return 5;
      case "/onboarding/add-bank":
        return 6;
      case "/onboarding/security":
        return 7;
      case "/onboarding/complete":
        return 8;
    }
  }

  return (
    <div className="onboarding-progress-wrapper">
      <MobileStepper
        variant="progress"
        steps={9}
        position="static"
        activeStep={activeStep}
        className="custom-stepper"
      />
    </div>
  );
}

export default OnboardingProgressBar;
