import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Badge, Button, Card, Col, Row } from "reactstrap";
import { getBankIcon } from "../../../../modules/Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faUniversity } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";
import useGQL from "../../../../api_client/UseGQL";
import { useToasts } from "react-toast-notifications";
import BanksDropdown from "../../../../components/dropdowns/BanksDropdown";
import { deleteBankAccount } from "../../../../api_client/mutations/bankAccount";
import SweetAlert from "react-bootstrap-sweetalert";
import { getBankAccount } from "../../../../api_client/queries/bankAccounts";
import { CustomLoader } from "../../../../components/loaders/CustomLoader";
import StatusBadgeBankAccount from "../../../../components/badge/StatusBadgeBankAccount";
import AddBilling from "../billing/AddBilling";
import VariableSporadicPAD from "../../../../components/PAD/VariableSporadicPAD";
import CopyToClipboard from "react-copy-to-clipboard";
import { CustomTooltip } from "modules/Helpers";

function BankInfo(props) {
  const history = useHistory();
  const [bankAccount, setBankAccount] = useState();
  const gqlHooks = useGQL();
  const { addToast } = useToasts();
  const [alertState, setAlertState] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updateBilling, setUpdateBilling] = useState(false);
  const [variablePad, setVariablePad] = useState(false);

  useEffect(() => {
    if (props.id) {
      setLoading(true);
      props.setBackdropClick(true);
      let input = {
        bankAccountId: props.id,
      };
      let output = {
        identifier: true,
        missingKyc: true,
        state: true,
        institution: true,
        caRoutingInfo: {
          transitNumber: true,
          accountNumber: true,
          institutionNumber: true,
        },
        title: true,
        accountType: true,
        holderName: true,
        holderAddress: true,
        holderAddressPostalCode: true,
        holderAddressCity: true,
        holderAddressCountry: true,
        holderEmail: true,
        billing: true,
        variableDebitPad: true,
      };
      getBankAccount(input, output, gqlHooks).then((response) => {
        if (response && response.data) {
          setBankAccount(response.data);
          setLoading(false);
          props.setBackdropClick(false);
        } else {
          addToast("Could not retrieve bank account", {
            appearance: "warning",
            autoDismiss: true,
          });
          props.toggle();
        }
      });
    } else {
      props.toggle();
    }
  }, [props.id]);

  const deleteAccount = () => {
    setLoading(true);
    props.setBackdropClick(true);
    let output = {
      ok: true,
    };
    deleteBankAccount({ bankAccountId: props.id }, output, gqlHooks).then(
      (response) => {
        if (response) {
          if (response.ok) {
            addToast("Bank account deleted", {
              appearance: "success",
              autoDismiss: true,
            });
            history.push({ search: "update=true" });
          } else {
            addToast("Could not delete bank account", {
              appearance: "error",
              autoDismiss: true,
            });
          }
        }
      }
    );
    setLoading(false);
    props.setBackdropClick(false);
    setAlertState(null);
  };

  function confirmDelete() {
    setAlertState(
      <SweetAlert
        warning
        title="Are you sure you want to delete this bank account?"
        onConfirm={() => deleteAccount(props.id)}
        onCancel={() => setAlertState(null)}
        confirmBtnText="Delete Account"
        showCancel
      />
    );
  }

  if (loading) {
    return <CustomLoader general={true} />;
  }

  const copyIdToClipboard = () => {
    addToast("Bank Account Id copied to clipboard", {
      appearance: "success",
      autoDismiss: true,
    });
  };

  return (
    <Fragment>
      {alertState}
      <div className="content">
        {updateBilling ? (
          <>
            <Row>
              <Col>
                <Button
                  className="btn-link details-close-btn"
                  disabled={loading}
                  onClick={() => setUpdateBilling(false)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
                <h3 className="font-weight-600 mt-1 mb-3">Billing account</h3>
              </Col>
            </Row>
            <AddBilling name={bankAccount.holderName} account={bankAccount} />
          </>
        ) : variablePad ? (
          <>
            <Row>
              <Col>
                <Button
                  className="btn-link details-close-btn"
                  disabled={loading}
                  onClick={() => history.goBack()}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
                <h3 className="font-weight-600 mt-1 mb-3">
                  Pre Authorized Debit Agreement (PAD)
                </h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <VariableSporadicPAD
                  isOpen={variablePad}
                  toggle={() => history.goBack()}
                  bankAccountIdentifier={bankAccount.identifier}
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col className="col-sm col-sm-6 col-md-3">
                <div className="t-card-row d-flex align-items-center">
                  <div className="bank-logo ">
                    {bankAccount.institution ? (
                      <img src={getBankIcon(bankAccount.institution)} alt="" />
                    ) : (
                      <FontAwesomeIcon icon={faUniversity} />
                    )}
                  </div>
                  <div className="d-flex align-items-center">
                    <b>{bankAccount.institution}</b>
                  </div>
                </div>
              </Col>
              <Col>
                <Button
                  className="btn-link details-close-btn"
                  disabled={loading}
                  onClick={() => history.goBack()}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
                <BanksDropdown
                  account={bankAccount}
                  setRefresh={() => history.push({ search: "update=true" })}
                  action={confirmDelete}
                  setBillingAccount={setUpdateBilling}
                  setVariablePad={setVariablePad}
                />
                {bankAccount.state === "CONFIRM_MICRODEPOSIT" && (
                  <Button
                    className="btn-primary btn-sm pull-right"
                    disabled={loading}
                    onClick={() =>
                      history.push({
                        search: `confirm=true&id=${bankAccount.identifier}`,
                      })
                    }
                  >
                    Confirm micro-deposits
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  {StatusBadgeBankAccount(bankAccount)}{" "}
                  {bankAccount.billing && (
                    <Badge
                      color="primary"
                      className="mb-0 bank-account-status-badge"
                      pill
                    >
                      Billing account
                    </Badge>
                  )}
                </div>
                <Card className="details-card shadow-sm border mt-4">
                  <Row className="details-row border-bottom">
                    <Col>
                      <h5>Routing info</h5>
                      <p>
                        {bankAccount.caRoutingInfo.institutionNumber}{" "}
                        {bankAccount.caRoutingInfo.transitNumber}{" "}
                        {bankAccount.caRoutingInfo.accountNumber}
                      </p>
                    </Col>
                  </Row>
                  <Row className="details-row border-bottom">
                    <Col>
                      <h5>Account title</h5>
                      <p>{bankAccount.title}</p>
                    </Col>
                  </Row>
                  <Row className="details-row border-bottom">
                    <Col>
                      <h5>Account type</h5>
                      <p>{bankAccount.accountType}</p>
                    </Col>
                  </Row>
                  <Row className="details-row border-bottom">
                    <Col>
                      <h5>Holder name</h5>
                      <p>{bankAccount.holderName}</p>
                    </Col>
                  </Row>
                  <Row className="details-row border-bottom">
                    <Col>
                      <h5>Holder address</h5>
                      <p>
                        {bankAccount.holderAddress},{" "}
                        {bankAccount.holderAddressCity}{" "}
                        {bankAccount.holderAddressPostalCode}
                      </p>
                    </Col>
                  </Row>
                  <Row className="details-row border-bottom">
                    <Col>
                      <h5>Holder email</h5>
                      <p>{bankAccount.holderEmail}</p>
                    </Col>
                  </Row>
                  <Row className="details-row border-bottom">
                    <Col>
                      <h5>Identifier</h5>
                    </Col>
                    <Col>
                      <div>
                        <CopyToClipboard
                          onCopy={() => copyIdToClipboard()}
                          text={bankAccount.identifier}
                        >
                          <Button className="btn-link details-row-button">
                            Copy
                          </Button>
                        </CopyToClipboard>
                      </div>
                    </Col>
                    <Col sm="12">
                      <p>
                        {" "}
                        <CustomTooltip
                          title="Click to copy"
                          placement="top"
                          arrow
                        >
                          <CopyToClipboard
                            onCopy={() => copyIdToClipboard()}
                            text={bankAccount.identifier}
                          >
                            <span style={{ cursor: "pointer" }}>
                              {bankAccount.identifier}
                            </span>
                          </CopyToClipboard>
                        </CustomTooltip>
                      </p>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </Fragment>
  );
}

export default BankInfo;

BankInfo.propTypes = {
  toggle: PropTypes.func,
  id: PropTypes.string,
  setBackdropClick: PropTypes.func,
};
