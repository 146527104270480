import React, { Fragment } from "react";
import { Col, Fade, Row, Button } from "reactstrap";
import VerifyMe from "../views/onboarding/generic/VerifyMe";
import PropTypes from "prop-types";
import FlinksCompleteCheck from "../assets/icons/FlinksCompleteCheck";
import VerificationImage from "../assets/img/verification/FaceVerificationIconUpdated.svg";
import { withApiValue } from "./WithApiValue";
import { appName, domains, nextOnboardingPage } from "./Helpers";
import { useHistory } from "react-router";

const config = {
  kyc: {
    approved: true,
    updateRequired: true,
  },
  company: {
    kyc: {
      refreshFields: true,
    },
  },
};

function PassBase(props) {
  const history = useHistory();

  if (
    props.kyc.approved &&
    !props.kyc.updateRequired &&
    !("Identity" in JSON.parse(props.company.kyc.refreshFields))
  ) {
    return (
      <Fragment>
        <Col>
          <Row>
            <Col>
              <div className="text-center mt-4 mb-5">
                <FlinksCompleteCheck
                  fill="#33CC7A"
                  width={70}
                  height={70}
                  className="m-auto d-block"
                />
                <h2 className="text-success">Verification complete!</h2>
              </div>
              <Button
                onClick={() =>
                  history.push(
                    "/onboarding/" +
                      nextOnboardingPage("identity", props.kycFlow)
                  )
                }
                className="m-auto d-block mt-4"
                color={"primary"}
              >
                Proceed
              </Button>
            </Col>
          </Row>
        </Col>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <Col className="m-auto text-center mt" style={{ maxWidth: 525 }}>
        <Fade>
          <div className="d-flex justify-content-center">
            <img
              width={100}
              height={100}
              src={VerificationImage}
              alt={appName}
              className="mb-3"
            />
          </div>
          <h2 className="text-center mb-2 font-weight-600">
            Verify your identity
          </h2>
          <p className="text-muted">
            Please have a <b>driver&rsquo;s license</b> ready to complete this
            step. This process will take <b>about 5 minutes</b> and help us
            build a secure system together.{" "}
            <a
              href={`https://${domains.HELP}/why-does-jetpay-need-this-information`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </a>
          </p>
          <Row className="justify-content-center mt-4">
            <VerifyMe onComplete={props.onComplete} />
          </Row>
        </Fade>
      </Col>
    </Fragment>
  );
}

export default withApiValue(PassBase, config);

PassBase.propTypes = {
  kyc: PropTypes.object,
  onComplete: PropTypes.func.isRequired,
  kycFlow: PropTypes.array,
};
