import { useEffect } from "react";
import { INDIVIDUAL } from "../modules/Helpers";
import useApiValue from "../hooks/useApiValue";
import { useHistory } from "react-router";

function OnboardingGateway(props) {
  const { values, kycFlow, loading } = useApiValue({
    isOwner: true,
    kyc: { submitted: true, validated: true, updateRequired: true },
    company: {
      companyType: true,
      kyc: { companyAttestation: true, submitted: true, updateRequired: true },
      billingAccount: { identifier: true },
      settings: { securityQuestion: true },
    },
    profile: { firstName: true, lastName: true },
  });
  const history = useHistory();

  useEffect(() => {
    if (values.kyc && values.profile && values.company && kycFlow.length > 0) {
      if (
        values.kyc.updateRequired ||
        values.company.kyc.updateRequired ||
        !values.kyc.submitted ||
        !values.company.kyc.submitted
      ) {
        history.push("/onboarding/" + kycFlow[0]);
      } else {
        if (!values.company.kyc.validated) {
          if (!values.profile.firstName || !values.profile.lastName) {
            history.push("/onboarding/concierge");
          } else if (values.company.companyType === INDIVIDUAL) {
            if (!values.company.kyc.companyAttestation) {
              history.push("/onboarding/individual-legal");
            } else if (!values.kyc.submitted) {
              history.push("/onboarding/identity");
            } else if (!values.company.billingAccount) {
              history.push("/onboarding/add-bank");
            } else if (!values.company.settings.securityQuestion) {
              history.push("/onboarding/security");
            } else {
              history.push("/onboarding/complete");
            }
          } else {
            if (!values.company.kyc.submitted) {
              history.push("/onboarding/organization-legal");
            } else if (!values.company.billingAccount) {
              history.push("/onboarding/add-bank");
            } else if (!values.company.settings.securityQuestion) {
              history.push("/onboarding/security");
            } else if (!values.kyc.submitted) {
              history.push("/onboarding/identity");
            } else {
              history.push("/onboarding/complete");
            }
          }
        }
      }
    }
  }, [loading]);

  return props.children;
}

export default OnboardingGateway;

OnboardingGateway.propTypes = {};
