import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useSimpleContext } from "../../../contexts/SimpleContext";
import PropTypes from "prop-types";

//
import { appName, CustomTooltip } from "../../../modules/Helpers";
import SavedBankInfoModal from "../saved/SavedBankInfoModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { trackEvent } from "../../../modules/analytics";
import useGQL from "../../../api_client/UseGQL";
import Cloud1 from "../../../assets/img/checkout/bank-cloud-1.svg";
import Cloud2 from "../../../assets/img/checkout/bank-cloud-2.svg";
import bankImage1 from "../../../assets/img/checkout/bank-connected-image1.svg";
import bankImage2 from "../../../assets/img/checkout/bank-connected-image2.svg";
import { notifyCompletedSupplierInvite } from "api_client/mutations/contacts";

const SuccessfullySavedBankAccount = () => (
  <div className="mb-4 ml-1 align-items-center" style={{ display: "flex" }}>
    <div className="mt-1">
      <FontAwesomeIcon icon={faCheckCircle} color="green" size="lg" />
    </div>
    <span className="text-lg font-weight-500 pl-2">
      Bank account has been successfully saved.{" "}
    </span>
  </div>
);

const SaveBankCheckBox = ({ setChecked, checked }) => {
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  return (
    <FormGroup check className="mb-4 mt-0">
      <Label className="form-check-label">
        <Input
          id="cb-1"
          className="form-check-input"
          type="checkbox"
          checked={checked}
          onChange={() => setChecked(!checked)}
        />
        <span className="form-check-sign">
          <span className="check" />
        </span>
        <span className="text-lg font-weight-500">
          Remember this bank account{" "}
          <CustomTooltip
            title={`You will be able to access your saved information via secure email/phone confirmation the next time you checkout on ${appName}.`}
            placement="top"
            arrow
            open={tooltipIsOpen}
            onOpen={() => setTooltipIsOpen(true)}
            onClose={() => setTooltipIsOpen(false)}
            leaveTouchDelay={10000}
          >
            <Button
              onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
              variant="contained"
              className="btn-link tooltip-button"
            >
              <i className="fas fa-info-circle" />
            </Button>
          </CustomTooltip>
        </span>
      </Label>
    </FormGroup>
  );
};

SaveBankCheckBox.propTypes = {
  setChecked: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
};

function BankConnectedStep(props) {
  const [state, setState] = useSimpleContext();
  const [bankSaved, setBankSaved] = useState(false);
  const [saveBankCheckBoxChecked, setSaveBankCheckBoxChecked] = useState(false);
  const history = useHistory();
  const gqlHooks = useGQL();

  BankConnectedStep.defaultProps = {
    collectBankInfoOnly: false,
  };

  useEffect(() => {
    if (props.collectBankInfoOnly === true) {
      trackEvent(
        "supplierInviteBankConnection",
        {
          contactId: props.contactIdentifier,
        },
        gqlHooks
      );
    } else {
      if (state.transaction) {
        if (state.transaction.direction === "CREDIT") {
          trackEvent(
            "creditBankConnected",
            {
              transactionId: state.transaction.identifier,
              companyId: state.transaction.company?.identifier,
              contactId: state.transaction.contact.identifier,
            },
            gqlHooks
          );
        } else {
          trackEvent(
            "debitBankConnected",
            {
              transactionId: state.transaction.identifier,
              companyId: state.transaction.company?.identifier,
              contactId: state.transaction.contact.identifier,
            },
            gqlHooks
          );
        }
      } else {
        trackEvent(
          "recurringBankConnected",
          {
            recurringId: state.recurringPlan.identifier,
            companyId: state.recurringPlan.company.identifier,
            contactId: state.recurringPlan.contact.identifier,
          },
          gqlHooks
        );
      }
    }
  }, []);

  return (
    <Fragment>
      <SavedBankInfoModal
        collectBankInfoOnly={props.collectBankInfoOnly}
        contactIdentifier={props.contactIdentifier}
        onBankAccountSaved={() => setBankSaved(true)}
        onClosed={() => setSaveBankCheckBoxChecked(false)}
        open={saveBankCheckBoxChecked}
        type="save"
        toggle={() => setSaveBankCheckBoxChecked(!saveBankCheckBoxChecked)}
      />
      <Container>
        <Row className="bg-default rounded-top bank-connect-bg">
          <Col className="m-auto pt-5" md="8" lg="8">
            <h3 className="text-white text-center pt-3 pb-2 success-heading bank-connect-heading">
              {" "}
              Success! Your bank is connected.
            </h3>
          </Col>
          <div className="bank-connected-image">
            <div className="cloud-images-body">
              <div className="cloud-images bank-connect-cloud">
                <img className="cloud-1" src={Cloud1} alt="Cloud" />
                <img className="cloud-2" src={Cloud2} alt="Cloud" />
              </div>
            </div>
            <div className="bank-image">
              <img className="bank-image-1" src={bankImage1} alt="" />
              <div className="bank-image-2-body">
                <img className="bank-image-2" src={bankImage2} alt="" />
              </div>
            </div>
          </div>

          {/* <div>
              <img src={Cloud1} alt="" />
              <img src={Cloud2} alt="" />
              <img src={bankImage1} alt="" />
              <img src={bankImage2} alt="" />
          </div> */}
          {/* <img
            className="ml-auto mr-auto d-block"
            src={BankConnectedImg2}
            alt="Bank Connected"
          /> */}
        </Row>
        <div className="payment-info">
          <Row>
            <Col>
              <div className="d-flex justify-content-between align-items-center mt-3">
                <h5 className="mb-0">New bank account</h5>{" "}
                <Button
                  aria-label="Change bank account"
                  className="btn-link btn-links btn-sm pr-0"
                  onClick={() => {
                    setState({
                      ...state,
                      bankAccount: undefined,
                    });
                    props.setBankMethod("");
                  }}
                >
                  Change...
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div
                style={{ background: "#4e4ef51a" }}
                className="p-3 rounded justify-content-between d-flex mb-4"
              >
                <span>{state?.bankAccount?.institution}</span>
                <span>{state?.bankAccount?.caRoutingInfo.accountNumber}</span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {state.bankAccount.previouslySaved === false &&
                (bankSaved ? (
                  <SuccessfullySavedBankAccount />
                ) : (
                  <SaveBankCheckBox
                    checked={saveBankCheckBoxChecked}
                    setChecked={setSaveBankCheckBoxChecked}
                  />
                ))}
              {props.collectBankInfoOnly !== true ? (
                <Button
                  onClick={() => {
                    history.push({
                      pathname: "review",
                      search: "paymentMethod=bankAccount",
                    });
                  }}
                  block
                  className="btn-primary btn-lg mb-4 mt-0"
                >
                  {state?.transaction?.direction === "DEBIT"
                    ? "Review & pay"
                    : "Review & accept"}
                </Button>
              ) : null}
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default BankConnectedStep;

BankConnectedStep.propTypes = {
  setBankMethod: PropTypes.func.isRequired,
};
