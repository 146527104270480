import React, { Fragment, useState } from "react";
import { Button, Col, FormGroup, Row } from "reactstrap";
import AsyncSelect from "react-select/async";
import {
  contactTagMapping,
  defaultToContact,
  Option,
  SingleValue,
} from "../../modules/Helpers";
import AddContactModal from "../modals/AddContactModal";
import { reportContactCreated, trackEvent } from "../../modules/analytics";
import useForm from "../../hooks/UseForms";
import useGQL, { useErrorHandlerGQL } from "../../api_client/UseGQL";
import AddIcon from "../../assets/icons/AddIcon";
import PropTypes from "prop-types";
import Skeleton from "@material-ui/lab/Skeleton";
import { withApiValue } from "../../modules/WithApiValue";
import { createContact } from "../../api_client/mutations/contacts";
import { useToasts } from "react-toast-notifications";
import { useGQLContext } from "../../api_client/client";
import { allContacts, getContact } from "api_client/queries/contacts";

const config = {
  company: { contactTagsMapping: true, name: true },
};

const Loader = () => <Skeleton className="rounded" height={45} width={300} />;

export const alreadyCreated = "Already Created";

export function useGQlCustomErrorHandler() {
  const [, dispatch] = useGQLContext();
  const defaultErrorHandler = useErrorHandlerGQL(dispatch, null);

  function errorHandler(response) {
    if (response.errors) {
      return defaultErrorHandler(response);
    } else {
      let data = Object.values(response.data)[0];
      if (
        data &&
        data.fieldErrors &&
        data.fieldErrors[0]["field"] === "email" &&
        data.fieldErrors[0]["message"].includes("already")
      ) {
        throw alreadyCreated;
      }

      return defaultErrorHandler(response);
    }
  }

  return useGQL(false, errorHandler);
}

function SelectContactField(props) {
  const [addContactModal, setAddContactModal] = useState(false);
  const { values, handleChange, errors, setValues } = useForm();
  let gqlHooks = useGQL(true);
  let contactGqlHooks = useGQlCustomErrorHandler();
  const { addToast } = useToasts();
  let getContactGqlHooks = useGQL();

  const fetchSearchContacts = async (inputValue) => {
    let output = {
      identifier: true,
      name: true,
      createdAt: true,
      email: true,
      contactType: true,
      tags: true,
      bankAccounts: {
        identifier: true,
        state: true,
        institution: true,
        title: true,
        caRoutingInfo: {
          accountNumber: true,
        },
      },
    };
    let input = {
      limit: 100,
      offset: 0,
    };

    if (inputValue) {
      input.search = inputValue;
    }

    let response = await allContacts(input, output, gqlHooks);
    if (response) {
      return response.data.map((contact) => {
        return {
          value: contact.identifier,
          label: `${contact.name} - ${contact.email} - ${contact.tags}`,
          email: contact.email,
          name: contact.name,
          tags: contact.tags.toString(),
          bankAccounts: contact.bankAccounts,
        };
      });
    }
  };

  function addContact(contact) {
    const input = {
      email: contact.email,
      name: contact.name,
      tags: contact.tags,
      emailInvite: false,
    };

    const output = {
      contact: {
        identifier: true,
      },
      fieldErrors: true,
    };

    trackEvent("addContact", {}, gqlHooks);

    createContact(input, output, contactGqlHooks)
      .then((response) => {
        if (response) {
          addToast("Contact added successfully!", {
            appearance: "success",
            autoDismiss: true,
          });
          reportContactCreated(gqlHooks);
          setValues({});

          // if (tags) {
          //   let newTags = [];
          //   tags.map((tag) => {
          //     newTags.push(tag.label);
          //   });
          //   tags = newTags;
          // }

          props.setContactAccount({
            value: response.contact.identifier,
            label: `${contact.name} - ${contact.email} - ${contact.tags}`,
            email: contact.email,
            name: contact.name,
            tags: contact.tags.toString(),
          });
        } else {
          addToast("Something went wrong, could not add contact", {
            appearance: "error",
            autoDismiss: true,
          });
          props.setContactAccount(defaultToContact);
        }
      })
      .catch((error) => {
        if (error === alreadyCreated) {
          // get contact
          const input = { email: contact.email };
          const output = {
            identifier: true,
            email: true,
            name: true,
            tags: true,
            bankAccounts: {
              identifier: true,
              state: true,
              institution: true,
              title: true,
              caRoutingInfo: {
                accountNumber: true,
              },
            },
          };
          getContact(input, output, getContactGqlHooks).then((response) => {
            if (response) {
              setValues({});
              const data = response.data;
              const contactAccount = {
                value: data.identifier,
                label: `${data.name} - ${data.email} - ${data.tags}`,
                email: data.email,
                name: data.name,
                tags: data.tags.toString(),
                bankAccounts: data.bankAccounts,
              };
              props.setContactAccount(contactAccount);
              addToast("Contact already exists.", {
                appearance: "success",
                autoDismiss: true,
              });
            }
          });
        }
      });
  }

  function toggleAddContactModal() {
    setAddContactModal(!addContactModal);
  }

  return (
    <Fragment>
      {addContactModal && (
        <AddContactModal
          toggle={toggleAddContactModal}
          isOpen={true}
          handleChange={handleChange}
          values={values}
          errors={errors}
          addContact={addContact}
          defaultTag={contactTagMapping(
            props.contactType,
            JSON.parse(props.company.contactTagsMapping)
          )}
        />
      )}
      <Row className="new-payment-contact-wrapper">
        <Button
          color="links"
          className="btn-link new-payment-new-contact-btn"
          onClick={toggleAddContactModal}
        >
          <AddIcon /> New contact
        </Button>
        <Col>
          <FormGroup>
            <AsyncSelect
              cacheOptions
              defaultOptions
              loadOptions={fetchSearchContacts}
              onChange={(option) => {
                props.setContactAccount(option);
              }}
              className="react-select default"
              classNamePrefix="react-select"
              name="contact"
              value={props.contactAccount}
              placeholder="Search by name..."
              components={{ Option, SingleValue }}
            />
          </FormGroup>
        </Col>
      </Row>
    </Fragment>
  );
}

export default withApiValue(SelectContactField, config, Loader);

SelectContactField.propTypes = {
  company: PropTypes.object,
  contactAccount: PropTypes.object.isRequired,
  setContactAccount: PropTypes.func.isRequired,
  contactType: PropTypes.string.isRequired,
};
