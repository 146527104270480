import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload, faTimes } from "@fortawesome/free-solid-svg-icons";
import LogoWithText from "../../../../assets/img/LogoWithText.png";
import useGQL from "../../../../api_client/UseGQL";
import { bill } from "../../../../api_client/queries/bills";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import {
  CustomTooltip,
  formatDateYear,
  formattedDollarValue,
  appName,
  domains,
} from "../../../../modules/Helpers";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";
import BillTransactionTable from "../../../../components/tables/BillTransactionTable";
import { trackEvent } from "../../../../modules/analytics";
import { CustomLoader } from "../../../../components/loaders/CustomLoader";

function Bill(props) {
  const [billDetails, setBillDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const { addToast } = useToasts();
  let history = useHistory();
  let gqlHooks = useGQL();

  useEffect(() => {
    if (props.id) {
      setLoading(true);
      props.setBackdropClick(true);
      const identifier = props.id;
      let input = {
        billId: identifier,
      };
      let output = {
        identifier: true,
        createdAt: true,
        updatedAt: true,
        subtotal: true,
        creditApplied: true,
        tax: true,
        month: true,
        year: true,
        transactions: {
          createdAt: true,
          identifier: true,
          amount: true,
          statement: true,
          description: true,
          padType: true,
          direction: true,
          states: {
            state: true,
            createdAt: true,
          },
        },
        total: true,
        numTransactions: true,
        numDebits: true,
        costDebits: true,
        numCredits: true,
        costCredits: true,
        numInternals: true,
        costInternals: true,
        numNsfs: true,
        costNsfs: true,
        amountTransacted: true,
        owner: {
          email: true,
          company: {
            name: true,
            billingAccount: {
              caRoutingInfo: {
                accountNumber: true,
              },
              institution: true,
              title: true,
              holderAddress: true,
              holderAddressPostalCode: true,
              holderAddressCity: true,
              holderAddressCountry: true,
            },
          },
        },
        payment: {
          amount: true,
          identifier: true,
        },
      };
      bill(input, output, gqlHooks).then((response) => {
        if (response) {
          setBillDetails(response.data);
        }
        setLoading(false);
        props.setBackdropClick(false);
      });
    } else {
      history.push({ search: "" });
      addToast("Could not load bill", {
        appearance: "warning",
        autoDismiss: true,
      });
    }
  }, []);

  function downloadBill() {
    window.print();
    trackEvent("downloadBill", {}, gqlHooks);
  }

  const copyIdToClipboard = () => {
    addToast("Identifier copied to clipboard", {
      appearance: "success",
      autoDismiss: true,
    });
    trackEvent("copyBillId", {}, gqlHooks);
  };

  if (loading) {
    return (
      <div className="content">
        <CustomLoader message="Gathering data... thank you for your patience." />
      </div>
    );
  }

  return (
    <Fragment>
      <div className="d-flex flex-row justify-content-end">
        <Button
          className="btn-link details-close-btn"
          disabled={loading}
          onClick={props.toggle}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </div>
      <div className="section-to-print">
        <div className="d-flex flex-column flex-md-row align-items-center justify-content-between border-bottom border-background">
          <div className="text-center text-md-left mb-3">
            <img
              src={LogoWithText}
              alt={`${appName} Logo`}
              width="180"
              className="d-block"
            />
          </div>
          <div className="text-center text-md-left mb-3">
            <Button
              type="button"
              href="tel:+18447845267"
              className="btn btn-link font-weight-500 btn-primary mt-2"
              style={{ paddingTop: "13px" }}
            >
              +1 (844) 784-5267
            </Button>
            <div className="d-inline-block hide-on-print">
              <Button
                onClick={downloadBill}
                size="sm"
                color="primary"
                className="mb-0"
              >
                <span className="d-inline-block">
                  <FontAwesomeIcon
                    className="mr-2 mt-1 mb-0"
                    icon={faFileDownload}
                  />
                </span>
                <span className="btn-wrapper--label d-inline-block">
                  Download
                </span>
              </Button>
            </div>
          </div>
        </div>
        <Row className="mt-3 mb-4">
          <Col md="8" sm="8" className="order-sm-1">
            <div className="text-left text-sm-right">
              <div className="text-uppercase text-primary mb-2 font-size-xs">
                Invoice ID
              </div>
              <h4 className="bd-invoice-id mb-4 font-weight-bold">
                <CustomTooltip title="Click to copy" placement="top" arrow>
                  <CopyToClipboard
                    onCopy={() => copyIdToClipboard()}
                    text={billDetails.identifier}
                  >
                    <p
                      id={"identifier"}
                      style={{ cursor: "pointer" }}
                      className="cursor-pointer mb-2"
                    >
                      {billDetails.identifier.toUpperCase()}
                    </p>
                  </CopyToClipboard>
                </CustomTooltip>
              </h4>
            </div>
          </Col>
          <Col md="4" sm="4" className="order-sm-0">
            <div>
              <h5 className="text-primary font-weight-600 mb-2 text-uppercase">
                Billed From
              </h5>
              <p className="mb-1 font-weight-600">{appName}</p>
              <p className="text-muted">100-1333 Johnston Street</p>
              <p className="text-muted">Vancouver, CA</p>
              <p className="text-muted">V6H 3R9</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="7" sm="12">
            <div className="mb-4">
              <h5 className="text-primary font-weight-600 mb-2 text-uppercase">
                Billed To
              </h5>
              <p className="mb-1 font-weight-600">
                {billDetails.owner.company.name}
              </p>
              <p className="text-muted text-capitalize">
                {billDetails.owner.company.billingAccount.holderAddress.toLowerCase()}
              </p>
              <p className="text-muted text-capitalize">
                {billDetails.owner.company.billingAccount.holderAddressCity.toLowerCase()}
                ,{" "}
                {billDetails.owner.company.billingAccount.holderAddressCountry}
              </p>
              <p className="text-muted">
                {billDetails.owner.company.billingAccount.holderAddressPostalCode.substr(
                  0,
                  3
                ) +
                  " " +
                  billDetails.owner.company.billingAccount.holderAddressPostalCode.substr(
                    3,
                    6
                  )}
              </p>
            </div>
          </Col>
          <Col md="5" sm="12">
            <h5 className="text-primary font-weight-600 mb-2 text-uppercase">
              Billing Details
            </h5>
            <div className="flex-row">
              <p className="d-inline-block text-muted mb-2">Billing Period</p>
              <p className="pull-right font-weight-600 mb-2">
                {" "}
                {moment(`${billDetails.year}-${billDetails.month}`).format(
                  "MMMM YYYY"
                )}
              </p>
            </div>
            <div className="flex-row">
              <p className="d-inline-block text-muted mb-2">Billing Account</p>
              <p className="pull-right font-weight-600 mb-2">
                {billDetails.owner.company.billingAccount.institution} -{" "}
                {billDetails.owner.company.billingAccount.title} -{" "}
                {
                  billDetails.owner.company.billingAccount.caRoutingInfo
                    .accountNumber
                }
              </p>
            </div>
            <div className="flex-row">
              <p className="d-inline-block text-muted mb-2">Invoice Date</p>
              <p className="pull-right font-weight-600 mb-2">
                {formatDateYear(billDetails.createdAt)}
              </p>
            </div>
            <div className="flex-row">
              <p className="d-inline-block text-muted mb-2">Payment Volume</p>
              <p className="pull-right font-weight-600 mb-2">
                {formattedDollarValue(billDetails.amountTransacted)}
              </p>
            </div>
            <div className="flex-row">
              <p className="d-inline-block text-muted mb-2">Total Payments</p>
              <p className="pull-right font-weight-600 mb-2">
                {billDetails.numTransactions}
              </p>
            </div>
          </Col>
        </Row>
        <Row className="pt-4">
          <Col md="5" className="ml-auto">
            <div className="flex-row">
              <h5 className="text-primary font-weight-600 mb-2 text-uppercase">
                Payment Summary
              </h5>
            </div>
            <div className="flex-row">
              <p className="d-inline-block text-muted mb-2">NSFs</p>
              <CustomTooltip
                title={`${
                  billDetails.numNsfs
                } NSFs totalling ${formattedDollarValue(billDetails.costNsfs)}`}
                placement="top"
                arrow
              >
                <p
                  className="pull-right font-weight-600 mb-2"
                  style={{ cursor: "pointer" }}
                >
                  {formattedDollarValue(billDetails.costNsfs)}{" "}
                  <span className="text-muted font-weight-400">
                    ({billDetails.numNsfs})
                  </span>
                </p>
              </CustomTooltip>
            </div>
            <div className="flex-row">
              <p className="d-inline-block text-muted mb-2">Internals</p>
              <CustomTooltip
                title={`${
                  billDetails.numInternals
                } internals totalling ${formattedDollarValue(
                  billDetails.costInternals
                )}`}
                placement="top"
                arrow
              >
                <p
                  className="pull-right font-weight-600 mb-2"
                  style={{ cursor: "pointer" }}
                >
                  {formattedDollarValue(billDetails.costInternals)}{" "}
                  <span className="text-muted font-weight-400">
                    ({billDetails.numInternals})
                  </span>
                </p>
              </CustomTooltip>
            </div>
            <div className="flex-row">
              <p className="d-inline-block text-muted mb-2">Payables</p>
              <CustomTooltip
                title={`${
                  billDetails.numCredits
                } payables totalling ${formattedDollarValue(
                  billDetails.costCredits
                )}`}
                placement="top"
                arrow
              >
                <p
                  className="pull-right font-weight-600 mb-2"
                  style={{ cursor: "pointer" }}
                >
                  {formattedDollarValue(billDetails.costCredits)}{" "}
                  <span className="text-muted font-weight-400">
                    ({billDetails.numCredits})
                  </span>
                </p>
              </CustomTooltip>
            </div>
            <div className="flex-row">
              <p className="d-inline-block text-muted mb-2">Receivables</p>
              <CustomTooltip
                title={`${
                  billDetails.numDebits
                } receivables totalling ${formattedDollarValue(
                  billDetails.costDebits
                )}`}
                placement="top"
                arrow
              >
                <p
                  className="pull-right font-weight-600 mb-2"
                  style={{ cursor: "pointer" }}
                >
                  {formattedDollarValue(billDetails.costDebits)}{" "}
                  <span className="text-muted font-weight-400">
                    ({billDetails.numDebits})
                  </span>
                </p>
              </CustomTooltip>
            </div>
            <div className="flex-row border-top border-background">
              <p className="d-inline-block text-muted mt-2 mb-2">Sub-Total</p>
              <CustomTooltip
                title={`${
                  billDetails.numTransactions
                } payments totalling ${formattedDollarValue(
                  billDetails.subtotal
                )}`}
                placement="top"
                arrow
              >
                <p
                  className="pull-right font-weight-600 mt-2 mb-2"
                  style={{ cursor: "pointer" }}
                >
                  {formattedDollarValue(billDetails.subtotal)}{" "}
                  <span className="text-muted font-weight-400">
                    ({billDetails.numTransactions})
                  </span>
                </p>
              </CustomTooltip>
            </div>
            <div className="flex-row">
              <p className="d-inline-block text-muted mb-2">Discount</p>
              <p className="pull-right font-weight-600 text-success mb-2">
                -${billDetails.creditApplied}
              </p>
            </div>
            <div className="flex-row">
              <p className="d-inline-block text-muted mb-2">Tax (5%)</p>
              <p className="pull-right font-weight-600 mb-2">
                ${billDetails.tax}
              </p>
            </div>
            <div className="flex-row mt-1">
              <h4 className="d-inline-block font-weight-600 mb-2">Total</h4>
              <h3 className="pull-right font-weight-600 mb-2">
                {formattedDollarValue(billDetails.total)}
              </h3>
            </div>
          </Col>
        </Row>
        <div className="hide-on-print">
          <h4 className="mt-3">Payments</h4>
          <BillTransactionTable billId={billDetails.identifier} />
        </div>
        <Row>
          <Col
            lg="6"
            sm="12"
            className="text-center text-md-left hide-on-print mb-3"
          >
            <p className="text-black-50" style={{ fontSize: "13px" }}>
              Thank you for using{" "}
              <a
                href={`https://${domains.PRIMARY}/`}
                target="_blank"
                rel="noopener noreferrer"
                className="font-weight-500"
              >
                {appName}
              </a>
              . <br />
            </p>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}

Bill.propTypes = {
  id: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  setBackdropClick: PropTypes.func,
};

export default Bill;
