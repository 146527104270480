import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Col,
  Fade,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import useForm from "../../../hooks/UseForms";
import { OwnersValidation } from "../../../modules/form_validation/OwnersValidation";
import OnboardingOwnerForm from "./OnboardingOwnerForm";
import useGQL from "../../../api_client/UseGQL";
import { trackEvent } from "../../../modules/analytics";
import { submitCompanyKyc } from "../../../api_client/mutations/kyc";
import { PulseLoader } from "react-spinners";
import success from "../../../assets/img/onboarding/AddOwnersCompleteUpdated.svg";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { withApiValue } from "../../../modules/WithApiValue";
import OnboardingSigningAuthoritiesForm from "../organization/OnboardingSigningAuthoritiesForm";
import {
  ORGANIZATION,
  addressProvinceCode,
  nextOnboardingPage,
} from "../../../modules/Helpers";
import { useToasts } from "react-toast-notifications";

const config = {
  company: {
    companyType: true,
    kyc: {
      ownersSubmitted: true,
      owners: true,
      signingAuthorities: true,
    },
  },
  email: true,
};

function OnboardingOwners(props) {
  const { handleSubmit } = useForm(submit, OwnersValidation);
  const [updateOwners, setUpdateOwners] = useState(false);
  const [loading, setLoading] = useState(false);
  const [owners, setOwners] = useState([
    {
      legalName: "",
      addressStreet: "",
      percentOwned: "0",
      addressCity: "",
      dateOfBirth: "",
      occupation: "",
      id: 0,
      addressPostalCode: "",
      addressProvince: "",
    },
  ]);
  const [signingAuthorities, setSigningAuthorities] = useState([
    {
      id: 0,
      legalName: "",
      emailAddress: "",
    },
  ]);
  const [certify, setCertify] = useState(false);
  let gqlHooks = useGQL();
  const history = useHistory();
  const { addToast } = useToasts();
  const [submitClicked, setSubmitClicked] = useState(false);

  const updateOwnerProvinceById = (id, province) => {
    setOwners((prevOwners) =>
      prevOwners.map(
        (owner) =>
          owner.id === id
            ? { ...owner, addressProvince: province } // Spread the previous owner object and update the province property for the matching id
            : owner // Return the owner object as is for non-matching ids
      )
    );
  };

  const provinceCodeValidation = () => {
    return owners.every((element) =>
      addressProvinceCode.includes(element.addressProvince)
    );
  };

  useEffect(() => {
    if (Boolean(localStorage.getItem("ownersSubmitted")) === true) {
      setSubmitClicked(true);
    }
    if (props.company && props.company.kyc.ownersSubmitted) {
      let oldOwnerList = JSON.parse(
        props.company.kyc.owners.replace(/'/g, '"')
      ).map((ownersData, index) => {
        ownersData.legalName = ownersData.legal_name;
        ownersData.percentOwned = ownersData.percent_owned;
        ownersData.id = index;
        ownersData.addressStreet = ownersData.address_street;
        ownersData.addressCity = ownersData.address_city;
        ownersData.dateOfBirth = ownersData.date_of_birth;
        ownersData.addressPostalCode = ownersData.address_postal_code;
        ownersData.addressProvince = ownersData.address_province;
        delete ownersData.legal_name;
        delete ownersData.percent_owned;
        delete ownersData.address_postal_code;
        delete ownersData.date_of_birth;
        delete ownersData.address_city;
        delete ownersData.address_province;
        delete ownersData.address_street;
        return ownersData;
      });
      let oldSigningAuthoritiesList = props.company.kyc.signingAuthorities
        ? JSON.parse(
            props.company.kyc.signingAuthorities.replace(/'/g, '"')
          ).map((ownersData, index) => {
            ownersData.legalName = ownersData.legal_name;
            ownersData.emailAddress = ownersData.email_address;
            ownersData.id = index;
            delete ownersData.legal_name;
            delete ownersData.email_address;
            return ownersData;
          })
        : [];

      setOwners(oldOwnerList);
      setSigningAuthorities(oldSigningAuthoritiesList);
    }
    setUpdateOwners(true);
    trackEvent("onboardingSetOwners", {}, gqlHooks);
  }, [props.company]);

  function submit() {
    setLoading(true);
    let ownersList = owners.map((owner) => {
      let companyOwner = JSON.parse(JSON.stringify(owner));
      delete companyOwner.id;
      return companyOwner;
    });
    let signingAuthoritiesList = signingAuthorities.map((signingAuthority) => {
      let signingAuthorities = JSON.parse(JSON.stringify(signingAuthority));
      delete signingAuthorities.id;
      return signingAuthorities;
    });

    if (!provinceCodeValidation()) {
      addToast("Invalid value of the provinces", {
        appearance: "warning",
        autoDismiss: true,
      });
      setLoading(false);
      return;
    }

    submitCompanyKyc(
      {
        owners: ownersList,
        signingAuthorities: signingAuthoritiesList,
        companyAttestation: certify,
      },
      {},
      gqlHooks
    ).then((response) => {
      console.log(response);
      if (response) {
        setUpdateOwners(false);
      }
      setLoading(false);
      setSubmitClicked(true);
      localStorage.setItem("ownersSubmitted", true);
    });
  }

  function removeOwner(index) {
    let newOwnersArray = [];
    owners.forEach((owner, i) => i !== index && newOwnersArray.push(owner));
    setOwners(newOwnersArray);
  }

  function removeSigningAuthorities(index) {
    let newOwnersArray = [];
    signingAuthorities.forEach(
      (signingAuthority, i) =>
        i !== index && newOwnersArray.push(signingAuthority)
    );
    setSigningAuthorities(newOwnersArray);
  }

  function ownersList() {
    return owners.map((owner, index) => {
      console.log(owner);
      const setOwner = (
        legalName,
        addressStreet,
        percentOwned,
        addressCity,
        dateOfBirth,
        occupation,
        addressPostalCode,
        addressProvince
      ) => {
        owner.legalName = legalName;
        owner.addressStreet = addressStreet;
        owner.percentOwned =
          props.company.companyType === ORGANIZATION ? percentOwned : "100";
        owner.addressCity = addressCity;
        owner.dateOfBirth = dateOfBirth;
        owner.occupation = occupation;
        owner.addressPostalCode = addressPostalCode;
        owner.addressProvince = addressProvince;
      };
      return (
        <OnboardingOwnerForm
          key={index}
          id={index}
          legalName={owner.legalName}
          addressStreet={owner.addressStreet}
          percentOwned={owner.percentOwned}
          addressCity={owner.addressCity}
          dateOfBirth={owner.dateOfBirth}
          occupation={owner.occupation}
          addressPostalCode={owner.addressPostalCode}
          addressProvince={owner.addressProvince}
          setOwner={setOwner}
          removeOwner={removeOwner}
          updateOwnerProvinceById={updateOwnerProvinceById}
          companyType={props.company.companyType}
        />
      );
    });
  }

  function signingAuthoritiesList() {
    return signingAuthorities.map((signingAuthorities, index) => {
      console.log(signingAuthorities);
      const setSigningAuthority = (legalName, emailAddress) => {
        signingAuthorities.legalName = legalName;
        signingAuthorities.emailAddress = emailAddress;
      };
      return (
        <OnboardingSigningAuthoritiesForm
          key={signingAuthorities.id}
          id={signingAuthorities.id}
          legalName={signingAuthorities.legalName}
          emailAddress={signingAuthorities.emailAddress}
          setSigningAuthority={setSigningAuthority}
          removeSigningAuthorities={removeSigningAuthorities}
        />
      );
    });
  }

  function toggleUpdateOwners() {
    setUpdateOwners(true);
    trackEvent("editOwners", {}, gqlHooks);
  }

  function addOwner() {
    let newOwnersArray = JSON.parse(JSON.stringify(owners));
    newOwnersArray.push({
      legalName: "",
      addressStreet: "",
      percentOwned: "",
      id: owners.length,
      addressCity: "",
      dateOfBirth: "",
      occupation: "",
      addressPostalCode: "",
      addressProvince: "",
    });
    setOwners(newOwnersArray);
  }

  function addSigningAuthorities() {
    let newOwnersArray = JSON.parse(JSON.stringify(signingAuthorities));
    newOwnersArray.push({
      legalName: "",
      emailAddress: "",
      id: signingAuthorities.length,
    });
    setSigningAuthorities(newOwnersArray);
  }

  return (
    <Fragment>
      <Row className="mr-0 ml-0">
        <Col className="m-auto" style={{ maxWidth: 600 }}>
          <Fade>
            {updateOwners ? (
              <Fragment>
                <h2 className="text-center mb-2 font-weight-600">
                  Add owners or directors
                </h2>
                <p className="text-muted text-center mb-0">
                  Add all owners who possess <b>25% or more</b> of the business.
                  <br /> If not applicable, add the business directors.
                </p>
                <Form className="v-form" onSubmit={handleSubmit}>
                  {ownersList()}
                  {props.company.companyType === ORGANIZATION ? (
                    <Row>
                      <Col className="text-center">
                        <Button
                          className="btn btn-link font-weight-600 btn-links mb-3"
                          size="sm"
                          onClick={addOwner}
                        >
                          <i className="fas fa-plus" /> Add new
                        </Button>
                      </Col>
                    </Row>
                  ) : null}
                  <h4>Signing Authorities</h4>
                  {signingAuthoritiesList()}
                  <Row>
                    <Col className="text-center">
                      <Button
                        className="btn btn-link font-weight-600 btn-primary mb-3"
                        size="sm"
                        onClick={addSigningAuthorities}
                      >
                        <i className="fas fa-plus" /> Add new
                      </Button>
                    </Col>
                  </Row>
                  <div className="onboarding-modal-footer-terms">
                    <FormGroup check>
                      <div>
                        <Label className="form-check-label">
                          <Input
                            id="cb-1"
                            className="form-check-input"
                            type="checkbox"
                            checked={certify}
                            onChange={() => setCertify(!certify)}
                          />
                          <span className="form-check-sign">
                            <span className="check" />
                          </span>
                          I certify all information provided is correct
                        </Label>
                      </div>
                    </FormGroup>
                  </div>
                  <Button
                    type="submit"
                    block
                    color={
                      !(owners.length === 0 || !certify) ? "primary" : "primary"
                    }
                    disabled={owners.length === 0 || !certify || loading}
                    className="mt-4"
                  >
                    {loading ? (
                      <PulseLoader color="white" size={10} />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Form>
              </Fragment>
            ) : (
              <Fragment>
                <div className="text-center">
                  <img
                    src={success}
                    alt="Owners Added"
                    width="100"
                    height="100"
                    className="m-auto d-block"
                  />
                  <h2 className="text-center mt-3 mb-4 font-weight-600">
                    Owners or directors submitted!
                    <i
                      style={{ fontSize: "14px" }}
                      className="ml-2 cursor-pointer text-primary"
                      onClick={toggleUpdateOwners}
                    >
                      Update
                    </i>
                  </h2>
                  <Button
                    disabled={!submitClicked}
                    onClick={() =>
                      history.push(
                        "/onboarding/" +
                          nextOnboardingPage("owners", props.kycFlow)
                      )
                    }
                    className="m-auto d-block mt-4"
                    color={!loading ? "primary" : "gray-600"}
                  >
                    Proceed
                  </Button>
                </div>
              </Fragment>
            )}
          </Fade>
        </Col>
      </Row>
    </Fragment>
  );
}

export default withApiValue(OnboardingOwners, config);

OnboardingOwners.propTypes = {
  company: PropTypes.object,
  kycFlow: PropTypes.array,
};
