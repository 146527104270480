import React, { useEffect, useState } from "react";
import { useSimpleContext } from "../../../contexts/SimpleContext";
import { useHistory, useLocation } from "react-router";
import useGQL, {
  useErrorHandlerGQL,
  useMixPanelGQL,
} from "../../../api_client/UseGQL";
import {
  acceptRecurringPlan,
  acceptRecurringPlanViaCreditCard,
} from "../../../api_client/mutations/recurringPlans";
import RecurringPaymentReviewDetails from "../components/RecurringPaymentReviewDetails";
import UnexpectedErrorAlert from "../../../components/sweet_alert/UnexpectedErrorAlert";
import { useGQLContext } from "../../../api_client/client";
import { trackEvent } from "../../../modules/analytics";
import QueryString from "query-string";

function AcceptRecurringReviewStep() {
  const location = useLocation();
  const search = QueryString.parse(location.search);
  const [state, setState] = useSimpleContext();
  const history = useHistory();
  const [alert, setAlert] = useState(null);
  const [, dispatch] = useGQLContext();
  const defaultErrorHandler = useErrorHandlerGQL(dispatch, false);
  const handleRecurringReviewError = (response) => {
    const errors = response?.errors;
    const fieldErrors = response.data?.acceptRecurringPlan?.fieldErrors;
    if (errors || fieldErrors) {
      UnexpectedErrorAlert(() => setAlert(null), setAlert);
    } else {
      return defaultErrorHandler(response);
    }
  };
  let gqlHooks = useGQL(false, handleRecurringReviewError);
  const mixPanelGqlHooks = useMixPanelGQL();
  const [loading, setLoading] = useState(false);

  const recurringPlanOutput = {
    recurringPlan: {
      identifier: true,
      active: true,
      start: true,
      end: true,
      nextRun: true,
      expired: true,
      fromBankAccount: {
        contact: {
          email: true,
        },
        institution: true,
        title: true,
        caRoutingInfo: {
          accountNumber: true,
        },
      },
    },
  };
  const handleRecurringPlanResponse = (response) => {
    if (response) {
      if (response.recurringPlan) {
        setState({
          ...state,
          recurringPlan: {
            ...state.recurringPlan,
            ...response.recurringPlan,
          },
        });
        history.push("success");
      }
    } else {
      trackEvent(
        "recurringCheckoutFailed",
        {
          transactionId: state.transaction.identifier,
          companyId: state.transaction.company?.identifier,
          contactId: state.transaction.contact.identifier,
          errorData: response,
        },
        mixPanelGqlHooks
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    trackEvent(
      "recurringReview",
      {
        recurringId: state.recurringPlan.identifier,
        companyId: state.recurringPlan.company.identifier,
        contactId: state.recurringPlan.contact.identifier,
      },
      mixPanelGqlHooks
    );
  }, []);

  async function acceptViaBankTransfer() {
    setLoading(true);
    let input = {
      bankAccountToken: state.bankAccount.bankAccountToken,
      recurringPlanId: state.recurringPlan.identifier,
      signaturePad: state.signaturePAD,
      signaturePadTime: state.signaturePADTime,
    };
    const response = await acceptRecurringPlan(
      input,
      recurringPlanOutput,
      gqlHooks
    );
    handleRecurringPlanResponse(response);
  }

  async function acceptViaCreditCard() {
    setLoading(true);
    let input = {
      customerCreditCardId: state.recurringPlan?.customerCreditCard?.identifier,
      recurringPlanId: state.recurringPlan.identifier,
    };
    const response = await acceptRecurringPlanViaCreditCard(
      input,
      recurringPlanOutput,
      gqlHooks
    );
    handleRecurringPlanResponse(response);
  }

  if (search.paymentMethod === "creditCard") {
    return (
      <>
        {alert}
        <RecurringPaymentReviewDetails
          paymentMethod="Credit card"
          paymentMethodBrand={state?.recurringPlan?.customerCreditCard?.brand}
          paymentMethodNumber={`Card ending in ${state?.recurringPlan?.customerCreditCard?.last4}`}
          paymentCharges={true}
          acceptAction={acceptViaCreditCard}
          loading={loading}
        />
      </>
    );
  } else {
    return (
      <>
        {alert}
        <RecurringPaymentReviewDetails
          paymentMethod="Bank transfer"
          paymentMethodBrand={state?.bankAccount?.institution}
          paymentMethodNumber={state?.bankAccount?.caRoutingInfo.accountNumber}
          paymentCharges={false}
          acceptAction={acceptViaBankTransfer}
          loading={loading}
        />
      </>
    );
  }
}

export default AcceptRecurringReviewStep;

AcceptRecurringReviewStep.propTypes = {};
