/* eslint-disable react-hooks/exhaustive-deps,react/display-name,react/prop-types */
import React, { Fragment, useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { formatDateYear, formattedDollarValue } from "../../modules/Helpers";
import useGQL from "../../api_client/UseGQL";
import { previousBills } from "../../api_client/queries/bills";
import moment from "moment";
import ReactTable from "./ReactTable";
import { CardBody } from "reactstrap";
import BillCard from "../cards/BillCard";
import BillSkeletonCard from "../skeleton/BillSkeletonCard";

function BillingTable(props) {
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  let gqlHooks = useGQL();

  const getData = useCallback(
    async ({ pageSize }) => {
      setLoading(true);
      let offset = 0;
      let params = {
        ...props.filters,
        offset: offset,
        limit: pageSize,
      };
      let output = {
        identifier: true,
        createdAt: true,
        month: true,
        year: true,
        numTransactions: true,
        amountTransacted: true,
        total: true,
        owner: {
          company: {
            billingAccount: {
              caRoutingInfo: {
                accountNumber: true,
              },
              institution: true,
              title: true,
            },
          },
        },
      };
      const response = await previousBills(params, output, gqlHooks);
      if (response) {
        setData(response.data);
        setCount(response.data.length);
      }
      setLoading(false);
    },
    [props.filters]
  );

  let columns = useMemo(
    () => [
      {
        Header: "Billing Period",
        accessor: "period",
        Cell: ({ cell }) => (
          <Fragment>
            <div className="font-weight-bold mt-2 mb-2">
              {moment(
                `${cell.row.original.year}-${cell.row.original.month}`
              ).format("MMMM YYYY")}
            </div>
          </Fragment>
        ),
      },
      {
        Header: "Invoice Date",
        accessor: "text",
        // eslint-disable-next-line no-unused-vars
        Cell: ({ cell }) => (
          <Fragment> {formatDateYear(cell.row.original.createdAt)}</Fragment>
        ),
      },
      {
        Header: "Payment Volume",
        accessor: "amountTransacted",
        // eslint-disable-next-line no-unused-vars
        Cell: ({ cell }) => (
          <Fragment>
            {" "}
            {formattedDollarValue(cell.row.original.amountTransacted)}
          </Fragment>
        ),
      },
      {
        Header: "Total Payments",
        accessor: "numTransactions",
        // eslint-disable-next-line no-unused-vars
        Cell: ({ cell }) => (
          <Fragment>{cell.row.original.numTransactions}</Fragment>
        ),
      },
      {
        Header: "Billing Account",
        accessor: "owner",
        // eslint-disable-next-line no-unused-vars
        Cell: ({ cell }) => (
          <Fragment>
            {" "}
            {cell.row.original.owner.company.billingAccount.institution} -{" "}
            {cell.row.original.owner.company.billingAccount.title} -{" "}
            {
              cell.row.original.owner.company.billingAccount.caRoutingInfo
                .accountNumber
            }
          </Fragment>
        ),
      },
      {
        Header: "Amount Billed",
        accessor: "total",
        style: { th: { textAlign: "right" } },
        // eslint-disable-next-line react/display-name
        Cell: ({ cell }) => (
          <div className="text-right">
            <div className="text-right font-weight-600">
              {formattedDollarValue(cell.row.original.total)}
            </div>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <CardBody className="pt-0 pb-0 pr-3">
        <ReactTable
          loading={loading}
          getData={getData}
          data={data}
          columns={columns}
          count={count}
          overlayType="bill"
          styles="transaction-table"
          MobileCard={BillCard}
          MobileSkeleton={BillSkeletonCard}
        />
      </CardBody>
    </>
  );
}

export default BillingTable;
BillingTable.propTypes = {
  filters: PropTypes.object,
  cards: PropTypes.bool,
};

BillingTable.defaultProps = {
  cards: false,
};
