import React, { Fragment } from "react";
import { Col, Row } from "reactstrap";
import { useSimpleContext } from "../../../contexts/SimpleContext";
import { CustomTooltip, formatDate } from "../../../modules/Helpers";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useToasts } from "react-toast-notifications";
import { CreditCardAccount, WithdrawalAccount } from "./WithdrawalAccount";
import { PlatformFee } from "./PlatformFee";

function PaymentInfo() {
  const [state] = useSimpleContext();
  const { addToast } = useToasts();

  const copyIdToClipboard = () => {
    addToast("Payment id copied to clipboard", {
      appearance: "success",
      autoDismiss: true,
    });
  };

  return (
    <Fragment>
      <div className="payment-info">
        <Row className="hide-unless-print">
          <Col>
            <h5>Deposit date</h5>
            <p>{formatDate(state.transaction.estDepositDate)}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Paid from</h5>
            <p>
              {state.transaction.direction === "DEBIT"
                ? state.transaction.contact.email
                : state.transaction.company.name}
            </p>
          </Col>
        </Row>
        <Row>
          {state.transaction.customerCreditCard ? (
            <Col>
              <CreditCardAccount
                customerCreditCard={state.transaction.customerCreditCard}
              />
            </Col>
          ) : (
            <Col>
              <WithdrawalAccount
                fromBankAccount={state.transaction.fromBankAccount}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            {state.transaction.customerCreditCard && (
              <PlatformFee amount={state.transaction.amount} />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Paid to</h5>
            <p>
              {state.transaction.direction === "DEBIT"
                ? state.transaction.company.name
                : state.transaction.contact.email}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Deposit account</h5>
            <p>
              {state.transaction.toBankAccount ? (
                `${state.transaction.toBankAccount.institution} - ${state.transaction.toBankAccount.title} - ${state.transaction.toBankAccount.caRoutingInfo.accountNumber}`
              ) : (
                <span className="text-muted font-italic">
                  Pending authorization
                </span>
              )}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Payment id</h5>
            <p>
              {" "}
              <CustomTooltip title="Click to copy" placement="top" arrow>
                <CopyToClipboard
                  onCopy={() => copyIdToClipboard()}
                  text={state.transaction.identifier}
                >
                  <span style={{ cursor: "pointer" }}>
                    {state.transaction.identifier}
                  </span>
                </CopyToClipboard>
              </CustomTooltip>
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Statement</h5>
            <p>{state.transaction.statement}</p>
          </Col>
        </Row>
        {state.transaction.description && (
          <Row>
            <Col>
              <h5>Notes</h5>
              <p className="font-italic">
                <q>{state.transaction.description}</q>
              </p>
            </Col>
          </Row>
        )}
      </div>
    </Fragment>
  );
}

export default PaymentInfo;

PaymentInfo.propTypes = {};
