import React, { Fragment } from "react";
import { Button } from "reactstrap";
import { appName, domains } from "../../modules/Helpers";
import LogoWithText from "../../assets/img/LogoWithText-Light.png";
import { useLocation } from "react-router";
import QueryString from "query-string";
import axios from "axios";
import { host } from "../../api_client/client";
import { useToasts } from "react-toast-notifications";
import { addPathToUrl } from "../../api_client/utils";

function AdyenHostedOnboarding() {
  const location = useLocation();
  const { addToast } = useToasts();

  const redirectToAdyenHOPLink = async () => {
    let search = QueryString.parse(location.search);
    const token = search.token;

    if (token) {
      const hopLink = await generateAdyenHOPLink(token);
      if (hopLink) {
        window.location.href = hopLink;
      }
    } else {
      addToast("Unable to authenticate request", {
        appearance: "error",
      });
      console.error("JWT token not provided");
    }
  };

  const generateAdyenHOPLink = async (token) => {
    const url = addPathToUrl(host, "/adyen/adyen-onboarding");
    const config = { headers: { Authorization: `Bearer ${token}` } };

    try {
      const response = await axios.post(url, {}, config);
      const hopLink = response.data["link"];
      return hopLink;
    } catch (error) {
      console.error(error);
      let errorMessage =
        "An error occured while generating Adyen Hosted Onboarding link. Please contact support for assistance.";
      if (error.response.status == "401") {
        errorMessage =
          "Authentication failed. Please contact support for assistance.";
      }
      addToast(errorMessage, {
        appearance: "error",
      });
      return undefined;
    }
  };

  return (
    <Fragment>
      <div className="flex-column d-flex auth-content flex-grow-1 bg-dark">
        <div className="d-flex flex-row">
          <div
            className="mr-auto mb-3 cursor-pointer"
            style={{ maxWidth: "150px" }}
            onClick={() => window.open(`https://${domains.PRIMARY}/`, "_self")}
          >
            <img src={LogoWithText} alt={`${appName} Logo`} />
          </div>
        </div>
        <div className="d-flex justify-content-center flex-grow-1 flex-column">
          <h2 className="text-white text-center">Go to Adyen Onboarding</h2>
          <div className="ml-auto mr-auto">
            <Button
              onClick={() => redirectToAdyenHOPLink()}
              className="sp-button btn-primary mr-3"
            >
              Launch
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AdyenHostedOnboarding;

AdyenHostedOnboarding.propTypes = {};
