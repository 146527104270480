import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import FlinksIframe from "../../../modules/FlinksIframe";
import { Button, Col, Container, Row } from "reactstrap";
import { createBankAccountViaFlinks } from "../../../api_client/mutations/bankAccount";
import useGQL from "../../../api_client/UseGQL";
import BankLoadingImg from "../../../assets/img/checkout/ConnectBankLoadingUpdated.svg";
import { useSimpleContext } from "../../../contexts/SimpleContext";
import { delay } from "../../../modules/Helpers";
import { publicBankAccount } from "../../../api_client/queries/bankAccounts";
import PaymentBackTitle from "../components/PaymentBackTitle";
import { trackEvent } from "../../../modules/analytics";
import Cloud1 from "assets/img/checkout/bank-cloud-1.svg";
import Cloud2 from "assets/img/checkout/bank-cloud-2.svg";
import backgroundImage from "assets/img/onlineLogin_bg_2.png";

function LoginOnlineMethod(props) {
  const [flinksLogin, setFlinksLogin] = useState("");
  const [flinksAccount, setFlinksAccount] = useState("");
  const [loading, setLoading] = useState(false);
  const gqlHooks = useGQL();
  const [takingLong, setTakingLong] = useState(false);
  const [state, setState] = useSimpleContext();

  useEffect(() => {
    if (props.collectBankInfoOnly === true) {
      trackEvent(
        "supplierInviteBankConnection",
        {
          contactId: props.contactIdentifier,
        },
        gqlHooks
      );
    } else {
      if (state.transaction) {
        if (state.transaction.direction === "CREDIT") {
          trackEvent(
            "creditLoginOnline",
            {
              transactionId: state.transaction.identifier,
              companyId: state.transaction.company?.identifier,
              contactId: state.transaction.contact.identifier,
            },
            gqlHooks
          );
        } else {
          trackEvent(
            "debitLoginOnline",
            {
              transactionId: state.transaction.identifier,
              companyId: state.transaction.company?.identifier,
              contactId: state.transaction.contact.identifier,
            },
            gqlHooks
          );
        }
      } else {
        trackEvent(
          "recurringLoginOnline",
          {
            recurringId: state.recurringPlan.identifier,
            companyId: state.recurringPlan.company.identifier,
            contactId: state.recurringPlan.contact.identifier,
          },
          gqlHooks
        );
      }
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTakingLong(true);
    }, 120000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line no-unused-vars
  }, []);

  useEffect(() => {
    return () => {
      setFlinksLogin("");
      setFlinksAccount("");
    };
  }, []);

  useEffect(() => {
    if (flinksLogin !== "" && flinksAccount !== "" && !loading) {
      setLoading(true);
      createFlinksBankAccount().then(() => {
        setLoading(false);
      });
    }
  }, [flinksLogin, flinksAccount]);

  async function createFlinksBankAccount() {
    let input = {
      bankAccount: {
        flinksLoginId: flinksLogin,
        flinksBankAccountId: flinksAccount,
        refresh: true,
      },
      contactId: props.contactIdentifier,
    };
    let output = {
      bankAccount: {
        identifier: true,
        missingKyc: true,
        state: true,
        institution: true,
        title: true,
        accountType: true,
        caRoutingInfo: {
          accountNumber: true,
          institutionNumber: true,
        },
        previouslySaved: true,
        bankAccountToken: true,
      },
    };
    let response = await createBankAccountViaFlinks(input, output, gqlHooks);
    if (response && response.bankAccount) {
      let bankAccountToken = response.bankAccount.bankAccountToken;
      response = await getFlinksBankAccount(response.bankAccount.identifier);
      setFlinksAccount("");
      setFlinksLogin("");
      if (response.bankAccount.state === "READY") {
        setState({
          ...state,
          bankAccount: {
            ...response.bankAccount,
            bankAccountToken: bankAccountToken,
          },
        });
        props.setBankMethod("connected");
        setLoading(false);
      } else if (response.bankAccount.state === "PARTIALLY_READY") {
        setState({
          ...state,
          bankAccount: {
            ...response.bankAccount,
            bankAccountToken: bankAccountToken,
          },
        });
        props.setBankMethod("update");
      } else if (response.bankAccount.state === "DEVELOPER_ERROR") {
        props.setBankMethod("manual");
        props.showManualEntryAlert();
      } else {
        props.setBankMethod("manual");
      }
    } else {
      props.setBankMethod("manual");
    }
  }

  async function getFlinksBankAccount(identifier) {
    let input = { bankAccountId: identifier };
    let output = {
      identifier: true,
      missingKyc: true,
      state: true,
      institution: true,
      title: true,
      caRoutingInfo: {
        accountNumber: true,
        institutionNumber: true,
      },
      accountType: true,
      previouslySaved: true,
    };
    let response = await publicBankAccount(input, output, gqlHooks);
    if (
      response?.data.state === "NOT_READY" ||
      response?.data.state === "PROCESSING"
    ) {
      await delay(3000);
      return await getFlinksBankAccount(identifier);
    } else {
      return { bankAccount: { ...response.data } };
    }
  }

  if (loading) {
    return (
      <Fragment>
        <Container className="rounded-top rounded-radial">
          <Row>
            <Col className="mt-4">
              {/* <CustomLoader
                customClass="text-white"
                timoutTime={10000}
                noBlankMessage={true}
              /> */}
              <h4 className="text-default text-center text-white my-3">
                Firing up the send-o-tron!
              </h4>
            </Col>
          </Row>
          <Row>
            <div
              style={{ top: "99px" }}
              className="cloud-images-body paymentSuccess cloud-images-body-absolute"
            >
              <div className="cloud-images">
                <img className="cloud-1" src={Cloud1} alt="Cloud" />
                <img className="cloud-2" src={Cloud2} alt="Cloud" />
              </div>
            </div>
            <Col sm="10" md="11" className="m-auto">
              <img
                className="pr-2 pl-2"
                src={BankLoadingImg}
                alt="Connecting Bank Account"
              />
            </Col>
          </Row>
          <Row className="my-5">
            <Col md="8" lg="8" className="m-auto">
              <p className="text-white text-center text-lg mb-4">
                Hang tight and keep this page open. This can take anywhere from
                a few seconds to a few minutes.
              </p>
              <Button
                onClick={() => props.setBankMethod("")}
                className="btn-simple btn-outline-white btn-white text-primary border-white- btn-lg mb-2 btn btn-secondary btn-block border-primary"
                block
              >
                Cancel
              </Button>
            </Col>
          </Row>
          {takingLong && (
            <>
              <Row className="mb-3">
                <Col md="8" lg="8" className="m-auto">
                  <span className="text-white text-center d-block">
                    Hmm, is this taking too long? <br /> Want to enter your
                    cheque details online?
                  </span>
                  <Button
                    onClick={() => props.setBankMethod("manual")}
                    className="btn-primary btn-lg mb-2"
                    block
                  >
                    Enter bank account details manually instead
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <PaymentBackTitle
          title={
            state?.transaction?.direction === "DEBIT"
              ? "Pay via Bank Transfer"
              : "Accept via Bank Transfer"
          }
          backAction={() => props.setBankMethod("")}
        />
        <Container className="bank-method-wrapper">
          <Row>
            <Col>
              <span className="text-xl font-weight-600">
                Login with your bank
              </span>{" "}
              <br />
              <span className="text-sm">
                OR{" "}
                <u
                  className="text-primary cursor-pointer"
                  onClick={() => props.setBankMethod("manual")}
                >
                  Enter bank account details manually instead
                </u>{" "}
                if you have issues logging in to your bank.
              </span>
            </Col>
          </Row>
          <div
            className="d-flex flex-grow-1 justify-content-center"
            style={{ height: "525px" }}
          >
            <FlinksIframe
              page="w-100"
              setLoginId={(data) => setFlinksLogin(data.loginId)}
              setAccountId={(data) => setFlinksAccount(data.accountId)}
              redirectFunc={() => props.setBankMethod("manual")}
            />
          </div>
        </Container>
        <Container className="payment-secondary-action">
          <Row>
            <Col md="8" lg="8" className="m-auto text-center">
              <span className="text-muted">Having problems logging in?</span>
              <Button
                onClick={() => props.setBankMethod("manual")}
                className="btn-lg btn-simple btn-primary bg-white mb-0"
                block
              >
                Enter bank account details manually instead
              </Button>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default LoginOnlineMethod;

LoginOnlineMethod.propTypes = {
  setBankMethod: PropTypes.func,
  showManualEntryAlert: PropTypes.func,
};
