/* eslint-disable react/display-name,react/prop-types */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getBankIcon } from "../../modules/Helpers";
import PropTypes from "prop-types";
import useGQL from "../../api_client/UseGQL";
import { allBankAccounts } from "../../api_client/queries/bankAccounts";
import { CustomLoader } from "../loaders/CustomLoader";
import ReactTable from "./ReactTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import StatusBadgeBankAccount from "../badge/StatusBadgeBankAccount";
import BankCard from "../cards/BankCard";
import TransactionCardSkeleton from "../skeleton/TransactionCardSkeleton";
import QueryString from "query-string";
import { useHistory, useLocation } from "react-router";

function BanksTable() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const gqlHooks = useGQL();
  const [count, setCount] = useState(0);
  const location = useLocation();
  const [update, setUpdate] = useState(false);
  const history = useHistory();

  useEffect(() => {
    let search = QueryString.parse(location.search);
    if (search.update) {
      setUpdate(!update);
      history.push({ search: "" });
    }
  }, [location]);

  const getData = useCallback(
    async ({ pageSize }) => {
      setLoading(true);
      let offset = 0;
      const params = {
        limit: pageSize,
        offset: offset,
      };
      let output = {
        identifier: true,
        missingKyc: true,
        state: true,
        caRoutingInfo: {
          institutionNumber: true,
          transitNumber: true,
          accountNumber: true,
        },
        institution: true,
        title: true,
        accountType: true,
        holderName: true,
        holderAddress: true,
        holderAddressPostalCode: true,
        holderAddressCity: true,
        holderAddressCountry: true,
        holderEmail: true,
        billing: true,
        variableDebitPad: true,
      };
      const response = await allBankAccounts(params, output, gqlHooks);
      if (response) {
        setData(response.data);
        setCount(response.count);
      }
      setLoading(false);
    },
    [gqlHooks.context.refresh, update]
  );

  let columns = useMemo(
    () => [
      {
        Header: "Institution",
        accessor: "institution",
        Cell: ({ cell }) => (
          <Fragment>
            <p>
              {cell.row.original.state === "NOT_READY" ? (
                <CustomLoader />
              ) : cell.row.original.state === "PARTIALLY_READY" ||
                cell.row.original.state === "PROCESSING_ERROR" ? (
                <i className="tim-icons icon-alert-circle-exc" />
              ) : (
                <img
                  className="icon-sm"
                  src={getBankIcon(cell.row.original.institution)}
                  alt=""
                />
              )}
              <span className="ml-3">{cell.row.original.institution}</span>
            </p>
          </Fragment>
        ),
      },
      {
        Header: "Account Name",
        accessor: "title",
      },
      {
        Header: "Account Number",
        accessor: "caRoutingInfo.accountNumber",
      },
      {
        Header: "Status",
        accessor: "state",
        Cell: ({ cell }) => (
          <Fragment>{StatusBadgeBankAccount(cell.row.original)}</Fragment>
        ),
      },
      {
        Header: "Billing Account",
        accessor: "billingAccount",
        Cell: ({ cell }) => (
          <Fragment>
            <p className="mt-2 mb-0 pl-5">
              {cell.row.original.billing === true && (
                <FontAwesomeIcon className="text-primary" icon={faCheck} />
              )}
            </p>
          </Fragment>
        ),
      },
    ],
    []
  );

  return (
    <>
      <ReactTable
        MobileSkeleton={TransactionCardSkeleton}
        MobileCard={BankCard}
        loading={loading}
        getData={getData}
        data={data}
        columns={columns}
        count={count}
        overlayType="bank"
      />
    </>
  );
}

export default BanksTable;

BanksTable.propTypes = {
  overrideData: PropTypes.array,
};
