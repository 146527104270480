import React, { Fragment, useEffect, useState } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import {
  SimpleContextProvider,
  useSimpleContext,
} from "../../contexts/SimpleContext";
import { publicContact } from "../../api_client/queries/contacts";
import useGQL from "../../api_client/UseGQL";
import useExitPrompt from "../../hooks/useExitPrompt";
import PopOutOverlay from "components/overlays/PopOutOverlay";
import AuthSupportForm from "components/forms/AuthSupportForm";
import PaymentNavbar from "components/navbars/PaymentNavbar";
import BankTransferMethod from "views/payments/methods/BankTransferMethod";
import CheckoutSecurityBanner from "components/generic_details/CheckoutSecurityBanner";
import TrustFooter from "components/footers/TrustFooter";

function SupplierInvite() {
  const [, setShowExitPrompt] = useExitPrompt(true);
  const { params } = useRouteMatch();
  const gqlHooks = useGQL();
  const [state, setState] = useSimpleContext();
  const history = useHistory();
  const [overlayDetails, setOverlayDetails] = useState(false);

  useEffect(() => {
    setShowExitPrompt(true);
    return () => {
      setShowExitPrompt(false);
    };
  }, []);

  useEffect(() => {
    const contactIdentifier = params.identifier;
    let input = { contactId: contactIdentifier };
    const output = {
      identifier: true,
      email: true,
      name: true,
      bankAccounts: {
        identifier: true,
        state: true,
        institution: true,
        title: true,
        caRoutingInfo: {
          accountNumber: true,
        },
      },
    };
    publicContact(input, output, gqlHooks).then((response) => {
      if (response) {
        if (response.data) {
          console.log("The response is:");
          console.log(response.data);
          setState({ contact: response.data });
        }
      } else {
        history.push("/lost");
      }
    });
  }, []);

  return (
    <Fragment>
      <SimpleContextProvider>
        <div className="payment-wrapper section-to-print">
          <PaymentNavbar
            summaryText={state?.contact?.name}
            amount={null}
            toggleSupport={() => setOverlayDetails(!overlayDetails)}
          />
          <PopOutOverlay
            isOpen={overlayDetails}
            toggle={() => {
              setOverlayDetails(!overlayDetails);
            }}
          >
            <AuthSupportForm
              toggleSupport={() => setOverlayDetails(!overlayDetails)}
            />
          </PopOutOverlay>
          <div className="payment-content">
            <div className="card border">
              <Switch>
                <Route
                  path={[
                    "/supplier-invite/:identifier",
                    "/supplier-invite/:identifier/*",
                  ]}
                  render={() => (
                    <BankTransferMethod
                      collectBankInfoOnly
                      contactIdentifier={params.identifier}
                    />
                  )}
                />
              </Switch>
              <CheckoutSecurityBanner />
            </div>
            <TrustFooter />
          </div>
        </div>
      </SimpleContextProvider>
    </Fragment>
  );
}

export default SupplierInvite;
