import { graphQLURL } from "./client";

export async function callGQL(query, auth) {
  let response = await fetch(graphQLURL, {
    method: "POST",
    headers: {
      Authorization: auth,
      "content-type": "application/json",
    },
    body: JSON.stringify({
      query: query,
    }),
  });
  return await response.json();
}

export function addPathToUrl(url, path) {
  if (url.endsWith("/")) {
    url = url.slice(0, -1);
  }

  if (!path.startsWith("/")) {
    path = "/" + path;
  }

  return url + path;
}
