import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Fade, Row } from "reactstrap";
import {
  retryIdentityVerification,
  savePassbaseReferenceId,
} from "../../../api_client/mutations/verification";
import useGQL from "../../../api_client/UseGQL";
import { PulseLoader } from "react-spinners";
import {
  reportVerificationComplete,
  trackEvent,
} from "../../../modules/analytics";
import PassBase from "../../../modules/PassBase";
import success from "../../../assets/img/onboarding/VerificationCompleteUpdated.svg";
import VerificationFailed from "../../../assets/img/onboarding/VerificationFailedUpdated.svg";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import { withApiValue } from "../../../modules/WithApiValue";
import { nextOnboardingPage } from "../../../modules/Helpers";

const config = {
  isOwner: true,
  company: {
    kyc: {
      updateRequired: true,
      resubmitted: true,
      refreshFields: true,
    },
  },
  kyc: {
    submitted: true,
    approved: true,
    updateRequired: true,
    resubmitted: true,
  },
  email: true,
};

function Verification(props) {
  const [complete, setComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  let gqlHooks = useGQL();
  const { addToast } = useToasts();
  const history = useHistory();
  const [submitClicked, setSubmitClicked] = useState(false);

  useEffect(() => {
    if (Boolean(localStorage.getItem("verificationSubmitted")) === true) {
      setSubmitClicked(true);
    }
    if (
      !props.kyc.updateRequired &&
      !(
        props.company.kyc.refreshFields &&
        "Identity" in JSON.parse(props.company.kyc.refreshFields)
      ) &&
      ((props.kyc && props.kyc.submitted) ||
        (props.kyc && props.kyc.resubmitted))
    ) {
      setComplete(true);
    }
  }, [props.kyc]);

  function toggleCompleteVerification() {
    setComplete(false);
  }

  function onComplete(
    referenceId,
    personaFieldsData,
    legalName,
    birthDate,
    documentType,
    documentId,
    documentRegion
  ) {
    setLoading(true);
    savePassbaseReferenceId(
      {
        inquiryId: referenceId,
        personaFieldsData: JSON.stringify(personaFieldsData),
        legalName: legalName,
        dateOfBirth: birthDate,
        documentType: documentType,
        documentNumber: documentId,
        documentRegion: documentRegion,
      },
      { ok: true },
      gqlHooks
    ).then((response) => {
      if (response) {
        setComplete(true);
        setSubmitClicked(true);
        localStorage.setItem("verificationSubmitted", true);
      }
      setLoading(false);
    });
    reportVerificationComplete(gqlHooks);
  }

  function resendEmail() {
    setLoading(true);

    let output = {
      ok: true,
    };

    retryIdentityVerification({}, output, gqlHooks).then((response) => {
      setLoading(false);
      if (response) {
        if (response.ok) {
          addToast("Email sent successfully!", {
            appearance: "success",
            autoDismiss: true,
          });
          trackEvent("resendVerificationEmail", {}, gqlHooks);
        } else {
          addToast("Error sending email, please contact support.", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
    });
  }

  if (complete) {
    return (
      <Fragment>
        <Col>
          <Row>
            <Col className="m-auto text-center" style={{ maxWidth: 525 }}>
              <Fade>
                <div className="text-center">
                  {props.kyc.approved === false ? (
                    <Fragment>
                      <img
                        src={VerificationFailed}
                        alt="Identity Verification Failed"
                        width={100}
                        height={100}
                        className="m-auto d-block"
                      />
                      <h2 className="text-center mt-3 mb-2 font-weight-600">
                        Identity Verification Failed
                      </h2>
                      <p className="text-muted mb-4">
                        You failed your identity verification review. An email
                        was sent to <b>{props.email}</b> with a new verification
                        link.
                      </p>
                      <span className="p text-muted">
                        Didn&apos;t receive the email?
                      </span>{" "}
                      <Button
                        onClick={resendEmail}
                        className="btn-link font-weight-bold p-0 m-0"
                        color="primary"
                      >
                        Resend email
                      </Button>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <img
                        src={success}
                        alt="Setup Complete"
                        width="100"
                        height="100"
                        className="m-auto d-block"
                      />
                      <h2 className="text-center mt-3 mb-4 font-weight-600">
                        Identity Verification Submitted!{" "}
                        <i
                          style={{ fontSize: "14px" }}
                          className="cursor-pointer text-primary"
                          onClick={toggleCompleteVerification}
                        >
                          Update
                        </i>
                      </h2>

                      <Button
                        disabled={!submitClicked}
                        onClick={() =>
                          history.push(
                            "/onboarding/" +
                              nextOnboardingPage("identity", props.kycFlow)
                          )
                        }
                        color={"primary"}
                        className="m-auto d-block mt-4"
                      >
                        {loading ? (
                          <PulseLoader color="white" size={10} />
                        ) : (
                          "Proceed"
                        )}
                      </Button>
                    </Fragment>
                  )}
                </div>
              </Fade>
            </Col>
          </Row>
        </Col>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <PassBase onComplete={onComplete} />
    </Fragment>
  );
}

export default withApiValue(Verification, config);

Verification.propTypes = {
  isOwner: PropTypes.bool,
  company: PropTypes.object,
  kyc: PropTypes.object,
  email: PropTypes.string,
  kycFlow: PropTypes.array,
};
